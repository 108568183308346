import { Form, FormValidationRules, InputField, MultiInputField, useForm } from '@kit/components/Form';
import React, { useCallback, useMemo } from 'react';
import { Contact } from '@generated/types/graphql';
import { useContactMutations } from '@hooks/useContacts';
import { ReactQueryKey } from '@enums';
import { useQueryClient } from 'react-query';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { InfoIcon } from '@kit/ui/icons/Info';
import { Trash2 } from 'react-feather';
import { ModalBody, ModalFooter, useConfirmDeleteModal } from '@common/PromiseModal';
import { FieldsRow, RelatedRecords, RelatedRecordList, NoRelatedRecords } from './styled';
import { RecordBadge } from './RecordBadge';

interface FormValues {
  name: string;
  phones: string[];
  emails: { value: string }[];
}

const addEmptyArrayItemIfEmpty = (values: string[]) => {
  if (values.length === 0) {
    return [{ value: '' }];
  }

  return values;
};

const mapContactToFormValues = (contact: Contact) => ({
  id: contact.id,
  name: contact.name,
  phones: addEmptyArrayItemIfEmpty(contact.phones.map((phone) => ({ value: phone }))),
  emails: addEmptyArrayItemIfEmpty(contact.emails.map((email) => ({ value: email })))
});
interface Props {
  initialValues?: Contact;
  onClose: () => void;
  recordId?: number;
  isRelatedRecordsShown?: boolean;
  isAllowedToDelete?: boolean;
}

const EMAIL_REGEX = /.+@.+\..+/;

const emailValidation = (values: string[]) => {
  if (!Array.isArray(values)) {
    return undefined;
  }

  const errors = values.reduce((acc, { value }, index) => {
    if (value && !EMAIL_REGEX.test(value)) {
      acc[`emails.${index}.value`] = 'Invalid email';
    }

    return acc;
  }, {} as FormValidationRules<FormValues>);

  return Object.keys(errors).length > 0 ? errors : undefined;
};

class ClientValidationError extends Error {
  constructor(errors: any) {
    super('Validation error');
    this.payload = {
      errors
    };
  }
}

export const ContactForm = ({
  initialValues,
  onClose,
  recordId,
  isRelatedRecordsShown = false,
  isAllowedToDelete = false
}: Props) => {
  const {
    create: { mutateAsync: create },
    update: { mutateAsync: update },
    delete: { mutateAsync: remove }
  } = useContactMutations();

  const isEdit = Boolean(initialValues?.id);

  const confirmDelete = useConfirmDeleteModal();

  const queryClient = useQueryClient();

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([ReactQueryKey.WorkspaceContacts]);
    queryClient.invalidateQueries([ReactQueryKey.RecordDetail]);
  }, [queryClient]);

  const postForm = async (values: FormValues) => {
    const emailErrors = emailValidation(values.emails);

    if (emailErrors) {
      throw new ClientValidationError(emailErrors);
    }

    const dto = {
      emails: values.emails.map(({ value }) => value.trim()).filter(Boolean),
      name: values.name,
      phones: values.phones.map(({ value }) => value.trim()).filter(Boolean)
      // relatedProjects: initialValues ? initialValues.contactProjects.map(({ id }) => id) : [recordId]
    };

    if (!isEdit && recordId) {
      dto.relatedProjects = [recordId];
    }

    if (isEdit) {
      await update({ id: initialValues?.id || 0, dto });
    } else {
      await create({ dto });
    }

    invalidateCache();

    onClose();
  };

  const handleDelete = useCallback(async () => {
    if (await confirmDelete('Are you sure you want to delete this contact? This action cannot be undone.')) {
      await remove(initialValues?.id);
      queryClient.invalidateQueries([ReactQueryKey.WorkspaceContacts]);
      onClose();
    }
  }, [onClose, queryClient, confirmDelete, initialValues?.id, remove]);

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: initialValues
      ? mapContactToFormValues(initialValues)
      : {
          name: '',
          emails: [{ value: '' }],
          phones: [{ value: '' }]
        }
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      name: {
        isRequired: true
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody>
        <FieldsRow>
          <InputField label="Name" name="name" control={control} />
          <div />
        </FieldsRow>

        <FieldsRow>
          <MultiInputField
            name="phones"
            control={control}
            label="Phone"
            firstInputLabel="Phone"
            addButtonText="Add phone"
          />

          <MultiInputField
            name="emails"
            control={control}
            label="Email"
            firstInputLabel="Email"
            validate={emailValidation}
            addButtonText="Add email"
          />
        </FieldsRow>

        {isRelatedRecordsShown && (
          <RelatedRecords>
            <h3>
              <InfoIcon size="16px" />
              Related records
            </h3>
            <RelatedRecordList>
              {initialValues?.contactProjects.length === 0 && <NoRelatedRecords>No related records</NoRelatedRecords>}
              {initialValues?.contactProjects.map((relatedRecord) => (
                <RecordBadge onClose={onClose} key={relatedRecord.id} record={relatedRecord} />
              ))}
            </RelatedRecordList>
          </RelatedRecords>
        )}
      </ModalBody>
      <ModalFooter>
        {isAllowedToDelete && initialValues?.contactProjects.length === 0 && (
          <Button className="delete-button" variant={ButtonVariant.Danger} onClick={handleDelete}>
            <Trash2 size="16px" />
            Delete
          </Button>
        )}
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          {isEdit ? 'Update' : 'Create'}
        </Button>
      </ModalFooter>
    </Form>
  );
};
