import { useCompanyProperties, useCompanyPropertiesMutations } from '@hooks/useCompanyProperties';
import { RecordDetail } from '@hooks/useRecordDetail';
import { Property, RecordType } from '@types';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, useForm } from '@kit/components/Form';
import { PropertyValueField } from '@features/Platform/Properties/PropertyValueField';
import { Skeleton } from '@material-ui/lab';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { isQuilValueEmpty } from '@utils/quill';
import { hasEntityAccessNew } from '@utils/roles';
import { EditableWrapper, FullDescriptionBody } from '../styled';
import { FieldWrapper, ValuePlaceholder } from './styled';

interface InnerProps {
  project: RecordDetail;
  property: Property;
}

const EditableFieldInner = ({ project: record, property }: InnerProps) => {
  const [isEdit, setIsEdit] = useState(false);

  const canEdit = hasEntityAccessNew(record, 'edit');

  const togglIsEdit = useCallback(() => {
    setIsEdit((prev) => !prev);
  }, []);
  const [isUpdating, setIsUpdating] = useState(false);

  const queryClient = useQueryClient();

  const name = property.isAdditional ? `additional.${property.id}` : property.mappedName;
  const { updateProjectProperty } = useCompanyPropertiesMutations();

  const postForm = async (values: any) => {
    setIsUpdating(true);

    await updateProjectProperty.mutateAsync({
      projectId: record.id,
      property,
      newValue: values[name]
    });

    queryClient.setQueryData([ReactQueryKey.RecordDetail, record.id], (oldData: any) => {
      if (!oldData) {
        return oldData;
      }

      return {
        ...oldData,
        [name]: values[name]
      };
    });

    setTimeout(() => {
      setIsEdit(false);
      setIsUpdating(false);
    }, 100);
  };
  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    mode: 'onBlur',
    defaultValues: {
      description: record.description
    }
  });

  useEffect(() => {
    form.reset({
      description: record.description
    });
  }, [form, record]);

  const handleChanged = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  if (isUpdating) {
    return <Skeleton animation="wave" height={50} />;
  }

  if (isEdit) {
    return (
      <FieldWrapper width="100%">
        <Form onSubmit={handleSubmit}>
          <PropertyValueField
            isAutoFocus
            name="description"
            property={property}
            control={form.control}
            onChanged={handleChanged}
          />
        </Form>
      </FieldWrapper>
    );
  }

  return (
    <EditableWrapper isEditable={canEdit} onClick={canEdit ? togglIsEdit : undefined}>
      {!isQuilValueEmpty(record.description) && (
        <FullDescriptionBody dangerouslySetInnerHTML={{ __html: record.description }} />
      )}
      {isQuilValueEmpty(record.description) && <ValuePlaceholder>-</ValuePlaceholder>}
    </EditableWrapper>
  );
};

interface Props {
  project: RecordDetail;
}

export const Description = ({ project }: Props) => {
  const { properties } = useCompanyProperties({ recordType: RecordType.PROJECT });
  const property = properties.find((property) => property.mappedName === 'description');

  if (!property) {
    return null;
  }

  return <EditableFieldInner project={project} property={property} />;
};
