import { updateTask } from '@components/Calendar/redux/actions';
import { ReactQueryKey } from '@enums';
import { PrivilegedTask, TaskStatus } from '@generated/types/graphql';
import { useAppDispatch } from '@hooks/store';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

export const useInlineUpdateField = (task: PrivilegedTask, onUpdated?: () => void) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    await dispatch(
      updateTask({
        id: task.id,
        isCompleted: task.isCompleted,
        projectId: task.project.id,
        ...values,
        assigneeId: 'assigneeId' in values ? values.assigneeId?.id ?? null : undefined
      })
    );

    queryClient.setQueryData([ReactQueryKey.Tasks, ReactQueryKey.TasksDetails, task.id], (data) => {
      if (!data) {
        return data;
      }

      return {
        ...data,
        ...values
      };
    });

    queryClient.invalidateQueries([ReactQueryKey.Tasks]);
    queryClient.invalidateQueries([ReactQueryKey.ProjectsListInitialGroupData]);
    queryClient.invalidateQueries([ReactQueryKey.ProjectsByIds]);

    setIsLoading(false);

    onUpdated?.({
      id: task.id,
      projectId: task.project.id,
      isCompleted: 'isCompleted' in values ? values.isCompleted : task.isCompleted
    });

    if (values.status === TaskStatus.Completed) {
      // Workflows executed async, so we need to wait a bit before invalidating queries
      setTimeout(() => {
        queryClient.invalidateQueries([ReactQueryKey.Tasks]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectsListInitialGroupData]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectsByIds]);
        queryClient.invalidateQueries([ReactQueryKey.RecordDetail, task.project.id]);
      }, 2000);
    }
  };

  return {
    isLoading,
    onSubmit: handleSubmit
  };
};
