import { colors, device, scroll } from '@styles';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Wrapper as CopyButton } from '@common/ui/CopyButton/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SearchContainer = styled.div`
  max-width: 216px;
`;

export const ContentContainer = styled.div`
  overflow: auto;
  ${scroll};

  display: flex;
  justify-content: space-between;
  gap: 64px;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Group = styled.div`
  background: #fff;
  padding: 16px;
  margin-bottom: 24px;
`;

export const GroupTitle = styled.h3`
  margin: 0 0 16px 0;
  font-size: 18px;
  font-weight: 500;
`;

export const PropertiesGrid = styled.div<{ maxHeight?: string }>`
  margin-top: 16px;
  display: grid;
  gap: 24px;
  row-gap: 16px;
  grid-template-columns: 1fr;
  > div {
    grid-column: 1;
  }

  ${device.md`
  grid-template-columns: repeat(3, minmax(0, 1fr));
  > div {
    grid-column: auto;
  }
  `};

  ${({ maxHeight }) =>
    maxHeight &&
    `
    max-height: ${maxHeight};
    overflow-y: auto;
    ${scroll};
  `};
`;

export const PropertyName = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #828d9a;
  margin-bottom: 4px;
`;

export const GroupsNav = styled.div`
  display: none;
  position: sticky;
  top: 0;
  width: 140px;
  min-width: 140px;
  overflow-y: auto;
  ${scroll};

  ${device.md`
    display: block;
  `}
`;

export const GroupNavItem = styled.div<{ isActive: boolean }>`
  height: 40px;

  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${({ isActive }) => (isActive ? colors.green : '#828D9A')};
    text-decoration: none;
  }
`;

export const EditableFieldContainer = styled.div<{ isReadOnly?: boolean }>`
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'default' : 'pointer')};
  border: 1px solid transparent;
  padding: 8px 12px;
  padding-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  min-height: 40px;
  transition: border-color 0.15s ease-in-out;
  border-left: 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -12px;
    top: -1px;
    bottom: -1px;
    width: 12px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid;
    border-color: transparent;
    border-right: 0;
    transition: border-color 0.15s ease-in-out;
  }

  &:hover {
    border-color: ${({ isReadOnly }) => (isReadOnly ? 'transparent' : '#e8ecef')};

    &:before {
      border-color: ${({ isReadOnly }) => (isReadOnly ? 'transparent' : '#e8ecef')};
    }
  }

  .ql-editor {
    padding-top: 0;
    padding-left: 0;
  }
`;

export const ValueHoverableWrapper = styled(Grid)`
  align-items: center;

  ${CopyButton} {
    margin-left: initial;
  }

  ${CopyButton} {
    margin-left: 4px;
  }

  ${CopyButton} {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.gray2};

    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  &:hover {
    ${CopyButton} {
      opacity: 1;
    }
  }

  ${({ theme }) => theme.devices.sm`
    ${CopyButton} {
      opacity: 0;
    }
  `}
`;

export const FormFieldWrapper = styled.div<{ isRelative: boolean }>`
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'initial')};
`;

export const FormFieldLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
`;

export const PropertyContainer = styled.div<{ isFullWidth: boolean }>`
  grid-column: ${({ isFullWidth }) => (isFullWidth ? '1 / -1 !important' : 'auto')};
`;

export const ProjectStatusOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
