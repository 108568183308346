import { flow, isString } from 'lodash';

import { isBrowser } from '@utils/utils';

export const MENTION_CLASS_NAME = 'cq-mention-strong';
export const TOKEN_CLASS_NAME = 'cq-token-strong';
export const MENTION_USER_TEXT_PATTERN = /\[([^\]]+?)\|~id:(\d+)]/g;
export const MENTION_TEAM_TEXT_PATTERN = /\[([^\]]+?)\|~team:(\d+)]/g;
export const TOKEN_TEXT_PATTERN = /\[(.+?)\|~property:([\w.]+)]/g;

const MENTION_QUILL_PATTERN = new RegExp(`(<strong class="${MENTION_CLASS_NAME}".+?</strong>)`, 'g');
// result is (<(span|strong) class="cq-token-strong".+?<\/\2>(\s?<\/span>)?)
const TOKEN_QUILL_PATTERN = new RegExp(`(<(span|strong) class="${TOKEN_CLASS_NAME}".+?</\\2>(\\s?</span>)?)`, 'g');

export const replacePatternsInString = (
  source: string,
  pattern: RegExp,
  replace: Parameters<String['replace']>[1]
): string => {
  if (!source || !isString(source)) {
    return source;
  }

  return source.replace(pattern, replace);
};

const getMentionEmbedElement = (name: string, id: string) =>
  `<strong class="${MENTION_CLASS_NAME}" data-denotation-char="@" data-user-id="${id}" data-value="${name}">@${name}</strong>`;

const getTeamEmbedElement = (teamName: string, teamId: string) =>
  `<strong class="${MENTION_CLASS_NAME}" data-denotation-char="@" data-team-id="${teamId}" data-value="${teamName}">@${teamName}</strong>`;

const getTokenEmbedElement = (title: string, propertyId: string) =>
  `<span class="${TOKEN_CLASS_NAME}" data-denotation-char="/" data-id="${propertyId}" data-value="${title}">/${title}</span>`;

export const getMentionAsMarkdown = (name: string, id: string) => `[${name}|~id:${id}]`;

export const getTeamMentionAsMarkdown = (name: string, teamId: string) => {
  const id = teamId.replace('team_', '');

  return `[${name}|~team:${id}]`;
};

const getTokenAsMarkdown = (title: string, propertyId: string) => `[${title}|~property:${propertyId}]`;

export const convertTextToQuillFormat = (value: string) => {
  return flow(
    ($value) =>
      replacePatternsInString($value, MENTION_USER_TEXT_PATTERN, (...rest) => {
        return getMentionEmbedElement(rest[1], rest[2]);
      }),
    ($value) =>
      replacePatternsInString($value, MENTION_TEAM_TEXT_PATTERN, (...rest) => {
        return getTeamEmbedElement(rest[1], rest[2]);
      }),
    ($value) =>
      replacePatternsInString($value, TOKEN_TEXT_PATTERN, (...rest) => {
        return getTokenEmbedElement(rest[1], rest[2]);
      }),
    ($value) =>
      replacePatternsInString($value, /(\n\n)/g, () => {
        return '<br>';
      })
  )(value);
};

export const convertQuillFormatToText = (value: string, plainText: boolean = false) => {
  if (!value) {
    return '';
  }

  return flow(
    ($value) =>
      replacePatternsInString($value, MENTION_QUILL_PATTERN, (...rest) => {
        const elem = document.createElement('div');
        const [match] = rest;
        elem.innerHTML = match;
        const dataset = elem.querySelector(`.${MENTION_CLASS_NAME}`)?.dataset;

        if (!dataset) {
          return '';
        }
        if (dataset.teamId || String(dataset.id).includes('team')) {
          return getTeamMentionAsMarkdown(dataset.value, dataset.teamId || dataset.id);
        }

        return getMentionAsMarkdown(dataset.value, dataset.userId || dataset.id);
      }),
    ($value) =>
      replacePatternsInString($value, TOKEN_QUILL_PATTERN, (...rest) => {
        const elem = document.createElement('div');
        const [match] = rest;

        elem.innerHTML = match;
        const dataset = elem.querySelector(`.${TOKEN_CLASS_NAME}`)?.dataset;

        if (plainText && dataset) {
          return dataset.value.replace(/.+:\s/, '') + ' ';
        }

        return dataset ? getTokenAsMarkdown(dataset.value, dataset.id) : '';
      }),
    ($value) =>
      replacePatternsInString($value, /(<br>)?<\/p><p>/g, () => {
        return '<br>';
      })
  )(value);
};

export const clearQuillValueForEmailsList = (value: string) => {
  if (!isBrowser) {
    return value;
  }

  const elem = document.createElement('div');
  elem.innerHTML = isString(value) ? value.replace(/<br>/g, '\n').replace(/&nbsp;/g, ' ') : value;

  return elem.innerText;
};

export const clearQuillValue = (value: string) => {
  if (!isBrowser) {
    return value;
  }

  const elem = document.createElement('div');
  elem.innerHTML = isString(value) ? value.replace(/<br>/g, '\n') : value;

  return elem.innerText;
};

export const clearQuillValueForSms = (value: string) => {
  if (!isBrowser) {
    return value;
  }

  const elem = document.createElement('div');
  elem.innerHTML = isString(value) ? value.replace(/<\/p><p>/g, '</p><br><p>').replace(/(\\n|<br>)/g, '\n') : value;

  return elem.innerText;
};

export const isQuilValueEmpty = (value?: string) =>
  !value ||
  value === '<p><br /></p>' ||
  value.length === 0 ||
  (value.slice(0, 11) === '<p><br></p>' && value.length === 11) ||
  !value.substring?.(3, value.length - 4).trim() ||
  value.replace(/&nbsp;|\s|<br>|<br \/>/g, '') === '<p></p>';
