export const formatHits = (hits: number) => (hits != null ? hits.toLocaleString('en-US', { useGrouping: true }) : '');

const powerToLocaleString = (watts: number) => watts.toLocaleString('en-US', { maximumFractionDigits: 2 });

export const formatPower = (watts: number) => {
  if (watts == null) {
    return '';
  }

  const abs = Math.abs(watts);

  if (abs < 1000) {
    return powerToLocaleString(watts) + ' W';
  } else if (abs < 1_000_000) {
    return powerToLocaleString(watts / 1000.0) + ' kW';
  } else if (abs < 1_000_000_000) {
    return powerToLocaleString(watts / 1_000_000) + ' MW';
  } else {
    return powerToLocaleString(watts / 1_000_000_000) + ' GW';
  }
};

const energyToLocaleString = (watthours: number) => watthours.toLocaleString('en-US', { maximumFractionDigits: 2 });

export const formatEnergy = (watthours: number) => {
  if (watthours == null) {
    return '';
  }

  const abs = Math.abs(watthours);

  if (abs < 1000) {
    return energyToLocaleString(watthours) + ' Wh';
  } else if (abs < 1_000_000) {
    return energyToLocaleString(watthours / 1000.0) + ' kWh';
  } else if (abs < 1_000_000_000) {
    return energyToLocaleString(watthours / 1_000_000) + ' MWh';
  } else {
    return energyToLocaleString(watthours / 1_000_000_000) + ' GWh';
  }
};
