import { useMemo } from 'react';
import { concat, isEmpty, negate, stubFalse } from 'lodash';

import { useAppSelector, useRecordDetail, useRoutes } from '@hooks';
import { useCompanyPropertiesWithoutRouter } from '@hooks/useCompanyProperties';
import { Property, RecordType, PropertyType } from '@types';
import { formatDate } from '@utils';
import { getPropertyValueById, isDate, isDateTime, isStage } from '@utils/properties';
import { selectWorkspaceId } from '@state/selectors';

const isDeal = (recordType: RecordType) => recordType === RecordType.DEAL;
const isProject = (recordType: RecordType) => recordType === RecordType.PROJECT;

const PREDEFINED_PROPERTIES = [
  {
    value: 'AHJ.Name',
    id: 'project.jurisdiction.name',
    hideIf: negate(isProject)
  },
  {
    value: 'Account.ID',
    id: 'project.parentProjectId',
    hideIf: negate(isDeal)
  },
  {
    value: 'Project.ID',
    id: 'project.id',
    hideIf: negate(isProject)
  }
];

const PREDEFINED_PROJECT_EMAILS = [
  {
    value: 'Contact.Emails',
    id: 'contact.emails',
    hideIf: stubFalse
  },
  {
    value: 'Request.Primary Email',
    id: 'project.primaryEmail',
    hideIf: negate(isDeal)
  },
  {
    value: 'Request.Primary Phone',
    id: 'project.primaryPhone',
    hideIf: negate(isDeal)
  }
  // {
  //   value: 'AHJ.Email',
  //   id: 'project.jurisdiction.emails[0]',
  //   hideIf: negate(isProject)
  // }
];

const PREDEFINED_PROJECT_PHONES = [
  {
    value: 'Contact.Phones',
    id: 'contact.phones',
    hideIf: stubFalse
  },
  {
    value: 'Deal.Primary Phone',
    id: 'project.primaryPhone',
    hideIf: negate(isDeal)
  }
  // {
  //   value: 'AHJ.Phone',
  //   id: 'project.jurisdiction.phones[0]',
  //   hideIf: negate(isProject)
  // }
];

const PREDEFINED_RECORD_CONTACTS = [
  {
    value: 'Contact.name',
    id: 'contact.name',
    hideIf: stubFalse
  },
  {
    value: 'Contact.firstName',
    id: 'contact.firstName',
    hideIf: stubFalse
  },
  {
    value: 'Contact.lastName',
    id: 'contact.lastName',
    hideIf: stubFalse
  }
];

const SALES_REP_PHONE = {
  value: 'Sales rep phone',
  id: 'project.salesRep.phone'
};

const SALES_REP_EMAIL = {
  value: 'Sales rep email',
  id: 'project.salesRep.email'
};

const PROJECT_MANAGER_PHONE = {
  value: 'Project manager phone',
  id: 'project.projectManager.phone'
};

const PROJECT_MANAGER_EMAIL = {
  value: 'Project manager email',
  id: 'project.projectManager.email'
};

const OWNER_PHONE = {
  value: 'Owner phone',
  id: 'project.owner.phone'
};

const OWNER_EMAIL = {
  value: 'Owner email',
  id: 'project.owner.email'
};

const WORK_ORDER_ASSIGNEE_EMAIL = {
  value: 'Work order.Assignee email',
  id: 'task.assignee.email'
};

const WORK_ORDER_ASSIGNEE_PHONE = {
  value: 'Work order.Assignee phone',
  id: 'task.assignee.phone'
};

const PREDEFINED_RECORD_USER_FIELDS = [
  {
    value: 'Project manager name',
    id: 'project.projectManagerId'
  },
  PROJECT_MANAGER_EMAIL,
  PROJECT_MANAGER_PHONE,
  {
    value: 'Sales rep name',
    id: 'project.salesRepId'
  },
  SALES_REP_EMAIL,
  SALES_REP_PHONE,
  {
    value: 'Owner name',
    id: 'project.ownerId'
  },
  OWNER_EMAIL,
  OWNER_PHONE
];

const TASK_FIELDS = [
  {
    value: 'Work order.Title',
    id: 'task.title',
    groupName: 'Work order properties'
  },
  {
    value: 'Work order.Priority',
    id: 'task.priority'
  },
  {
    value: 'Work order.Description',
    id: 'task.description'
  },
  {
    value: 'Work order.Collaborator Names',
    id: 'task.assignees'
  },
  {
    value: 'Work order.Labels',
    id: 'task.labels'
  },
  {
    value: 'Work order.Start Date',
    id: 'task.startDate'
  },
  {
    value: 'Work order.End Date',
    id: 'task.endDate'
  },
  {
    value: 'Work order.Status',
    id: 'task.status'
  },
  {
    value: 'Work order.Completed on',
    id: 'task.completionDate'
  },
  {
    value: 'Work order.Next Visit Date',
    id: 'task.nextVisit.startDate'
  },
  WORK_ORDER_ASSIGNEE_EMAIL,
  WORK_ORDER_ASSIGNEE_PHONE
];

const APPOINTMENT_FIELDS = [
  {
    value: 'Appointment.Title',
    id: 'appointment.title'
  },
  {
    value: 'Appointment.Description',
    id: 'appointment.description'
  },
  {
    value: 'Appointment.Due Date',
    id: 'appointment.dueDate'
  },
  {
    value: 'Appointment.Assignee',
    id: 'appointment.assignee'
  }
];

// owner
const EXCLUDED_PROPERTIES = [-31];

const RECORD_TYPE_TITLE_MAP: Record<RecordType, string> = {
  [RecordType.ACCOUNT]: 'Client',
  [RecordType.PROJECT]: 'Project',
  [RecordType.DEAL]: 'Request'
};

const createValues = (
  properties: Property[],
  recordType: RecordType,
  filterPropertiesByRecordType = false,
  filterOutAppointmentTokens = true
) => {
  const typeAdderToPredefined = (c) => ({
    value: `${RECORD_TYPE_TITLE_MAP[recordType]}.${c.value}`,
    id: c.id
  });

  const mappedProps = (
    filterPropertiesByRecordType
      ? properties.filter(({ scope, id }) => (scope ?? []).includes(recordType) && !EXCLUDED_PROPERTIES.includes(id))
      : properties
  ).reduce((acc, c) => {
    if (!c.isAdditional || c.type !== PropertyType.Person) {
      return acc.concat([
        {
          value: `${RECORD_TYPE_TITLE_MAP[recordType]}.${c.name}`,
          id: `project.${c.mappedName}${c.isAdditional ? `.${c.id}` : ''}`,
          property: c
        }
      ]);
    }

    return acc.concat(
      ['firstName', 'lastName', 'email', 'phone'].map((path) => ({
        value: `${RECORD_TYPE_TITLE_MAP[recordType]}.${c.name} ${path.toLowerCase()}`,
        id: `project.${c.mappedName}.${c.id}.${path}`,
        property: c
      }))
    );
  }, []);

  const filteredPredefinedProperties = PREDEFINED_PROPERTIES.filter(
    ({ hideIf }) => !filterPropertiesByRecordType || !hideIf(recordType)
  );
  const filteredPredefinedProjectEmails = PREDEFINED_PROJECT_EMAILS.filter(
    ({ hideIf }) => !filterPropertiesByRecordType || !hideIf(recordType)
  );
  const filteredPredefinedProjectPhones = PREDEFINED_PROJECT_PHONES.filter(
    ({ hideIf }) => !filterPropertiesByRecordType || !hideIf(recordType)
  );
  const filteredPredefinedRecordContacts = PREDEFINED_RECORD_CONTACTS.filter(
    ({ hideIf }) => !filterPropertiesByRecordType || !hideIf(recordType)
  );
  const filteredPredefinedOwnerFields = PREDEFINED_RECORD_USER_FIELDS.map(typeAdderToPredefined);

  const base = concat(
    filteredPredefinedProperties,
    filteredPredefinedProjectEmails,
    filteredPredefinedRecordContacts,
    filteredPredefinedOwnerFields,
    mappedProps,
    TASK_FIELDS,
    filterOutAppointmentTokens ? [] : APPOINTMENT_FIELDS
  );

  const contacts = concat(
    [WORK_ORDER_ASSIGNEE_PHONE],
    filteredPredefinedProjectPhones,
    mappedProps,
    ...[OWNER_PHONE, PROJECT_MANAGER_PHONE, SALES_REP_PHONE].map(typeAdderToPredefined)
  );

  return {
    mappedProps,
    allTokens: base,
    forToField: concat(
      [WORK_ORDER_ASSIGNEE_EMAIL],
      filteredPredefinedProjectEmails,
      mappedProps,
      ...[OWNER_EMAIL, PROJECT_MANAGER_EMAIL, SALES_REP_EMAIL].map(typeAdderToPredefined)
    ),
    forPhoneField: contacts
  };
};

export const useTokens = (
  recordType: RecordType,
  filterPropertyByRecordType = false,
  filterOutAppointmentTokens = true
) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { properties: allProperties, scopeToColumns } = useCompanyPropertiesWithoutRouter(companyId, {
    recordType
  });
  const properties = filterPropertyByRecordType ? scopeToColumns[recordType] : allProperties;

  const isLoading = (allProperties || []).length === 0;

  const tokens = useMemo(
    () => createValues(properties || [], recordType, filterPropertyByRecordType, filterOutAppointmentTokens),
    [properties, recordType, filterPropertyByRecordType, filterOutAppointmentTokens]
  );

  return useMemo(
    () => ({
      isLoading,
      getAllTokens: () => tokens.allTokens,
      getTokensFieldTo: () => tokens.forToField,
      getTokensFieldPhone: () => tokens.forPhoneField,
      mappedProps: () => tokens.mappedProps
    }),
    [tokens, isLoading]
  );
};

export const useTokensWithValuesWithRecordId = (recordId: number, recordType: RecordType) => {
  const { data: record } = useRecordDetail(recordId!, { refetchOnMount: false });

  const { getAllTokens, getTokensFieldTo } = useTokens(recordType);

  return useMemo(
    () => ({
      getAllTokens: () =>
        getAllTokens()
          .map(({ value, id, property }) => {
            let propertyValue = property && getPropertyValueById({ projectDetail: record }, property);

            if (isDate(property)) {
              propertyValue = formatDate(propertyValue, isDateTime(property) ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY');
            }

            if (isStage(property)) {
              propertyValue = propertyValue?.name;
            }

            return (
              !isEmpty(propertyValue) && {
                value: `${value}: ${propertyValue}`,
                id
              }
            );
          })
          .filter(Boolean),
      getTokensFieldTo: () =>
        getTokensFieldTo()
          .map(({ value, id, property }) => {
            let propertyValue = property && getPropertyValueById({ projectDetail: record }, property);

            if (isDate(property)) {
              propertyValue = formatDate(propertyValue, isDateTime(property) ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY');
            }

            return (
              !isEmpty(propertyValue) && {
                value: `${value}: ${propertyValue}`,
                id
              }
            );
          })
          .filter(Boolean)
    }),
    [record, getAllTokens, getTokensFieldTo]
  );
};

export const useTokensWithValues = () => {
  const { recordId, recordType } = useRoutes();

  return useTokensWithValuesWithRecordId(recordId, recordType);
};
