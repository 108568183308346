import { useRecordDetail } from '@hooks/useRecordDetail';
import React, { useCallback } from 'react';
import { CopyButton } from '@common/ui';
import { useModal } from '@common/PromiseModal';
import { Home, MapPin } from 'react-feather';
import moment from 'moment';
import { capitalize } from 'lodash';

import { getGeoLink } from '@utils/getGeoLink';
import { ContactForm } from '@features/ClientPortfolio/Client/Overview/Stakeholders/ContactForm';
import { UserAvatar } from '@kit/components/UserAvatar';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { formatMoney } from '@utils/utils';
import { Status } from '@features/RequestPortfolio/components/Status';
import { STATUS_CONFIG } from '@features/RequestPortfolio/constants';
import { Badge } from '@kit/ui/Badge';
import { BadgeSize } from '@kit/ui/Badge/types';
import { BuildingIcon } from '@kit/ui/icons/Building';
import { AccountType } from '@types';
import { hasEntityAccessNew } from '@utils/roles';
import {
  Chips,
  Container,
  EditableWrapper,
  InfoBlock,
  PrimaryContactBlock,
  ProjectContact,
  ProjectContactInfo,
  ProjectContactLabel,
  ProjectContacts,
  Copyable,
  AddressAndIcon,
  InfoAddress,
  StaticPropertyValue,
  EditableChipPlaceholder,
  ReferredBy,
  StatusContainer
} from './styled';
import { Description } from './EditableFields';
import { EditableField } from './EditableField';
import { CustomProperties } from './CustomProperties';

interface Props {
  projectId: number;
}

export const Properties = ({ projectId }: Props) => {
  const { data: record, isLoading } = useRecordDetail(projectId);
  const { openModal } = useModal();

  const canEdit = record && hasEntityAccessNew(record, 'edit');

  const primaryContact = record?.projectContacts[0];

  const handleEditPrimaryContact = useCallback(() => {
    openModal<void>(
      ({ onClose }) => <ContactForm recordId={projectId} initialValues={primaryContact} onClose={onClose} />,
      { title: 'Edit contact' }
    );
  }, [projectId, primaryContact, openModal]);

  if (isLoading || !record?.id) {
    return null; // TODO
  }

  return (
    <Container>
      <InfoBlock>
        <Copyable>
          <AddressAndIcon>
            <InfoAddress href={getGeoLink(record.geoLocation, record.address)} target="_blank" rel="noreferrer">
              <MapPin size={13} />
              <div>{record.address?.[0] || 'No address specified'}</div>
            </InfoAddress>
          </AddressAndIcon>

          <CopyButton value={record.address?.[0]} />
        </Copyable>

        <Chips>
          {record.account && (
            <Badge bgColor="#E4E8EC" color="#1D1D35" size={BadgeSize.Large}>
              {record.account.accountType === AccountType.RESIDENTIAL ? (
                <Home color="#9C9CAA" size="16px" />
              ) : (
                <BuildingIcon color="#9C9CAA" size="16px" />
              )}
              {capitalize(record.account.accountType)}
            </Badge>
          )}

          <EditableField project={record} propertyName="trades">
            <Badge bgColor={canEdit ? '#fff' : '#E4E8EC'} color="#1D1D35" size={BadgeSize.Large}>
              {!record.trades?.length && <EditableChipPlaceholder>Select trades</EditableChipPlaceholder>}
              {record.trades?.length > 0 && <div>Trades: {capitalize(record.trades?.join(', '))}</div>}
            </Badge>
          </EditableField>

          <EditableField project={record} propertyName="dealSize">
            <Badge bgColor={canEdit ? '#fff' : '#E4E8EC'} color="#1D1D35" size={BadgeSize.Large}>
              {record.dealSize} kW
            </Badge>
          </EditableField>
          <EditableField project={record} propertyName="dealValue">
            <Badge bgColor={canEdit ? '#fff' : '#E4E8EC'} color="#1D1D35" size={BadgeSize.Large}>
              <span className="infoChipLabel">
                <span className="infoChipCurrencySymbol">$&nbsp;</span>
              </span>
              {formatMoney(record.dealValue).replace('$', '')}
            </Badge>
          </EditableField>
        </Chips>

        {record.projectContacts.length > 0 && (
          <PrimaryContactBlock>
            <div>
              <ProjectContactLabel>Primary contact</ProjectContactLabel>
            </div>

            <ProjectContacts>
              <EditableWrapper isEditable={canEdit} onClick={canEdit ? handleEditPrimaryContact : undefined}>
                <Copyable>
                  <ProjectContact>{primaryContact.name}</ProjectContact>
                  <CopyButton value={primaryContact.name} />
                </Copyable>
              </EditableWrapper>

              {primaryContact.phones?.[0] ? (
                <Copyable>
                  <PhoneNumber value={primaryContact.phones[0]} />
                  <CopyButton value={primaryContact.phones?.[0]} />
                </Copyable>
              ) : (
                <div />
              )}

              {primaryContact.emails?.[0] ? (
                <Copyable>
                  <EmailAddress value={primaryContact.emails[0]} />
                  <CopyButton value={primaryContact.emails?.[0]} />
                </Copyable>
              ) : (
                <div />
              )}
            </ProjectContacts>
          </PrimaryContactBlock>
        )}

        <ProjectContacts>
          <ProjectContact>
            <ProjectContactLabel>Owner</ProjectContactLabel>
            <EditableField project={record} propertyName="ownerId">
              <EditableWrapper isEditable={canEdit}>
                <ProjectContactInfo>
                  {record.owner?.id && (
                    <>
                      <UserAvatar user={record.owner} />
                      <span>{`${record.owner.firstName} ${record.owner.lastName}`}</span>
                    </>
                  )}

                  {!record.owner && '-'}
                </ProjectContactInfo>
              </EditableWrapper>
            </EditableField>
          </ProjectContact>

          <ProjectContact>
            <ProjectContactLabel>Sales rep</ProjectContactLabel>
            <EditableField project={record} propertyName="salesRepId">
              <EditableWrapper isEditable={canEdit}>
                <ProjectContactInfo>
                  {record.salesRep && (
                    <>
                      <UserAvatar user={record.salesRep} />
                      <span>{`${record.salesRep.firstName} ${record.salesRep.lastName}`}</span>
                    </>
                  )}
                  {!record.salesRep && '-'}
                </ProjectContactInfo>
              </EditableWrapper>
            </EditableField>
          </ProjectContact>

          <ProjectContact>
            <ProjectContactLabel>Project manager</ProjectContactLabel>
            <EditableField project={record} propertyName="projectManagerId">
              <EditableWrapper isEditable={canEdit}>
                <ProjectContactInfo>
                  {record.projectManager && (
                    <>
                      <UserAvatar user={record.projectManager} />
                      <span>{`${record.projectManager.firstName} ${record.projectManager.lastName}`}</span>
                    </>
                  )}

                  {!record.projectManager && '-'}
                </ProjectContactInfo>
              </EditableWrapper>
            </EditableField>
          </ProjectContact>
        </ProjectContacts>

        {record.referrerContact && (
          <ProjectContact>
            <ProjectContactLabel>Referred by</ProjectContactLabel>
            <ReferredBy>
              <ProjectContactInfo>
                <ContactAvatar contact={record.referrerContact} />
                <span>{record.referrerContact.name}</span>
              </ProjectContactInfo>
            </ReferredBy>
          </ProjectContact>
        )}

        <div>
          <ProjectContactLabel>Description</ProjectContactLabel>
          <Description project={record} />
        </div>

        <ProjectContacts>
          <div>
            <ProjectContactLabel>Created at</ProjectContactLabel>
            <StaticPropertyValue>{moment(record.createdAt).format('MM/DD/YY hh:mma')}</StaticPropertyValue>
          </div>
          <div>
            <ProjectContactLabel>Last activity</ProjectContactLabel>
            <StaticPropertyValue>{moment(record.updatedAt).format('MM/DD/YY hh:mma')}</StaticPropertyValue>
          </div>
          <div>
            <ProjectContactLabel>Request Status</ProjectContactLabel>

            <StatusContainer>
              <Status status={record.requestStatus} />
              {STATUS_CONFIG[record.requestStatus]?.label}
            </StatusContainer>
          </div>
        </ProjectContacts>
      </InfoBlock>

      <CustomProperties recordId={projectId} />
    </Container>
  );
};
