import styled, { css } from 'styled-components';
import { scroll } from '@styles';

export const Container = styled.div`
  background-color: #f7f7fc;
  height: 100%;
  width: 752px;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding: 16px 24px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background-color: #fff;
  white-space: nowrap;

  button {
    flex-shrink: 0;
  }
`;

export const Content = styled.div`
  padding: 24px;
  height: 100%;
  overflow: auto;
  max-height: calc(100vh - 56px - 64px);
  ${scroll};
`;

export const TitleInputWrapper = styled.div`
margin-left:-12px;
margin-right: auto;
min-width: 0;
> div {
 margin-bottom: 0;
 }
&& {
.MuiOutlinedInput-input {
    font-size: 20px;
    line-height: 24px;

    font-weight: 500;
    padding: 9px 12px;
    padding-left: 13px;
    height: auto;
    letter-spacing normal;
    field-sizing: content;

    ::placeholder {
        font-size: 20px;
        color: #828D9A;
        font-weight: 500;
        font-style: normal;
    }
}
}
`;

export const EditableFieldContainer = styled.div<{ isDisabled?: boolean; isPlaceholder?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  border: 1px solid transparent;
  padding: 8px 12px;
  border-radius: 4px;
  min-height: 40px;
  line-height: 22px;
  color: ${(props) => (props.isPlaceholder ? '#828D9A' : '#000')};

  ${(props) =>
    !props.isDisabled &&
    css`
      cursor: pointer;
      transition:
        border-color 0.15s ease-in-out,
        background-color 0.15s ease-in-out;

      &:hover {
        border-color: #e8ecef;
        background-color: #fff;
      }
    `};

  .ql-editor {
    padding-top: 0;
    padding-left: 0;
  }
`;

export const TitleView = styled(EditableFieldContainer)<{ isPlaceholder: boolean }>`
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.isPlaceholder ? '#828D9A' : '#000')};
  border: 1px solid transparent;
  padding: 8px 12px;
  border-radius: 4px;
  min-height: 40px;
  background-color: transparent;
`;

export const FieldLabel = styled.div<{ fixPadding?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;

  padding-top: ${(props) => (props.fixPadding ? '14px' : 0)};
`;

export const FieldBlock = styled.div<{ isBlock?: boolean; isCentered?: boolean }>`
  display: ${(props) => (props.isBlock ? 'block' : 'flex')};
  align-items: ${(props) => (props.isCentered ? 'center' : 'flex-start')};
  gap: 16px;
  margin-bottom: 16px;

  > *:last-child {
    flex: 1;
    margin-bottom: 0;
  }

  .ql-editor {
    a {
      word-break: break-word;
    }
  }

  ${FieldLabel} {
    margin-bottom: ${(props) => (props.isBlock ? '8px' : 0)};
    width: ${(props) => (props.isBlock ? 'auto' : '80px')};
  }
`;

export const SelectValueView = styled(EditableFieldContainer)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
`;

export const DescriptionWrapper = styled.div`
  label + div {
    margin-left: -8px;
  }
`;

export const DescriptionView = styled(EditableFieldContainer)`
  padding: 6px 7px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.15008px;
  line-height: 18px;
  border: 1px solid transparent;
  white-space: pre-wrap;
`;

export const PlaceholderValue = styled.div`
  color: #9c9caa;
  font-size: 14px;
  font-weight: 400;
`;

export const AvatarAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AssigneeValueView = styled(SelectValueView)`
  flex-wrap: wrap;
`;

export const OptionWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TwoFields = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  > * {
    flex: 1;
    margin-bottom: 16px;
    min-width: 0;
`;
