import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { useAppSelector } from '@hooks/store';
import { selectCompanyTimezone, selectWorkspaceId } from '@state/selectors';
import { WidgetData } from '../Widget/types';
import { WidgetSettingsError } from '../WidgetSettingsError';
import { fetchRecordsData } from './records';
import { AnalyticsModule, WidgetSettings } from '../types';
import { WidgetDataParams } from './types';
import { fetchWorkOrdersData } from './workOrders';
import { InternalConfigutationError } from '../InternalConfigurationError';
import { usePropertiesMap, useStagesMap, useTeamsMap, useUserToTeamsMap, useUsersMap } from '../hooks';
import { isSettingsValid } from '../helpers';
import { useWorkOrderStatusesMap } from '../hooks/useWorkOrderStatusesMap';
import { useWorkOrderTemplatesMap } from '../hooks/useWorkOrderTemplatesMap';
import { useStageIdsByWorkflowMap } from '../hooks/useStageIdsByWorkflowMap';

const fetchWidgetData = async (args: WidgetDataParams): Promise<WidgetData> => {
  switch (args.settings.module) {
    case AnalyticsModule.CLIENTS:
    case AnalyticsModule.PROJECTS:
    case AnalyticsModule.REQUESTS:
      return fetchRecordsData(args);
    case AnalyticsModule.WORK_ORDERS:
      return fetchWorkOrdersData(args);
    default:
      throw new WidgetSettingsError('Invalid module type');
  }
};

export const useWidgetData = (settings: WidgetSettings) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const timezone = useAppSelector(selectCompanyTimezone);

  const { usersMap, isUsersLoading } = useUsersMap();
  const { stagesMap, isStagesLoading } = useStagesMap();
  const { stageIdsByWorkflowMap, isWorkflowsLoading } = useStageIdsByWorkflowMap();
  const { userToTeamsMap, isTeamsLoading } = useUserToTeamsMap();
  const { teamsMap, isLoading: isTeamsMapLoading } = useTeamsMap();
  const { propertiesMap, isPropertiesLoading } = usePropertiesMap(settings.module);
  const { workOrderStatusesMap, isWorkOrderStatusLoading } = useWorkOrderStatusesMap();
  const { workOrderTemplatesMap, isWorkOrderTemplatesLoading } = useWorkOrderTemplatesMap();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, subtitle, ...dataSettingsOnly } = settings;

  return useQuery(
    [ReactQueryKey.AnalyticsData, companyId, { ...dataSettingsOnly }],
    async () => {
      try {
        const response = await fetchWidgetData({
          companyId,
          settings,
          usersMap,
          stagesMap,
          userToTeamsMap,
          propertiesMap,
          timezone,
          workOrderStatusesMap,
          workOrderTemplatesMap,
          stageIdsByWorkflowMap,
          teamsMap
        });

        return response;
      } catch (error: unknown) {
        if (error instanceof WidgetSettingsError) {
          return { error: error.message };
        }

        if (error instanceof InternalConfigutationError) {
          return { error: `Internal configuration error: ${error.message}` };
        }

        throw new Error(error as any);
      }
    },
    {
      retry: false,
      refetchOnMount: false,
      enabled:
        !isUsersLoading &&
        !isStagesLoading &&
        !isTeamsLoading &&
        !isTeamsMapLoading &&
        !isPropertiesLoading &&
        !isWorkOrderStatusLoading &&
        !isWorkOrderTemplatesLoading &&
        !isWorkflowsLoading &&
        isSettingsValid(settings)
    }
  );
};
