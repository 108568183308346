import { useMemo } from 'react';

import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { MainUser, User, Team } from '@generated/types/graphql';
import { useAppSelector } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { useRecordDetail } from './useRecordDetail';
import { useTeams } from './useTeams';

const normalizeUsers = (collection: (MainUser | User)[], excludeIds: number[], groupName: string) =>
  collection
    .filter(({ id }) => !excludeIds.includes(id))
    .map(({ id, lastName, firstName, avatarUrl }, index) =>
      index === 0
        ? {
            id,
            avatarUrl,
            value: `${firstName} ${lastName}`,
            groupName,
            isTeam: false
          }
        : {
            id,
            avatarUrl,
            value: `${firstName} ${lastName}`,
            isTeam: false
          }
    );

const normalizeTeams = (collection: Team[], excludeIds: number[], groupName: string) =>
  collection
    .filter(({ id }) => !excludeIds.includes(id))
    .map((team, index) => {
      return {
        id: `team_${team.id}`,
        value: team.name,
        avatarUrl: team.imageUrl,
        groupName: index === 0 ? groupName : undefined,
        usersLength: team.teamUsers.length,
        isTeam: true
      };
    });

export const useMentions = (projectId?: number) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { data: teams } = useTeams(companyId);
  const { data: recordDetail, isLoading } = useRecordDetail(projectId, { refetchOnMount: false });
  const { data: users, isLoading: isUsersLoading } = useAllCompaniesUsers();

  const getMentions = useMemo(
    () => () => {
      const normalizedMembers = normalizeUsers(
        recordDetail?.projectMembers.map(({ member }) => member) ?? [],
        [],
        'Project members'
      );

      const normalizedUsers = normalizeUsers(
        users,
        normalizedMembers.map(({ id }) => id),
        'Workspace members'
      );

      const normalizedTeams = normalizeTeams(teams, [], 'Workspace teams');

      return [...normalizedMembers, ...normalizedUsers, ...normalizedTeams];
    },
    [recordDetail?.projectMembers, users, teams]
  );

  return {
    isLoading: isLoading || isUsersLoading,
    getMentions
  };
};
