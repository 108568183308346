import React, { useCallback } from 'react';
import { AlertTriangle, EyeOff } from 'react-feather';
import { useSystem } from '@hooks/systems/useSystem';
import { NotMatchtedLabel, StatusMark } from '@features/SystemPortfolio/components';
import { Tooltip } from '@material-ui/core';
import { SYSTEM_STATUS_COLORS } from '@styles';
import { Address } from '@kit/ui/anchors/Address';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useModal } from '@common/PromiseModal';
import { MatchToClient } from '@features/SystemPortfolio/components/MatchToClient';
import { ProjectTitleChip } from '@components/Scheduler/components/ProjectTitleChip';
import { getStatusDesc } from '@features/SystemPortfolio/constants';
import { Container, FirstRow, Info, InfoTitle, PreTitle, SecondRow, Title } from './styled';
import { HeaderSkeleton } from './Skeleton';
import { FleetEditableChip } from '../FleetEditableChip';

interface Props {
  systemId: number;
}

export const Header = ({ systemId }: Props) => {
  const { data: system, isLoading } = useSystem(systemId);

  const { openModal } = useModal();

  const handleLink = useCallback(() => {
    openModal<void>(({ onClose }) => <MatchToClient system={system} onClose={onClose} />, {
      title: `Link #${system.number}: ${system.name} to Client`
    });
  }, [openModal, system]);

  if (isLoading) {
    return <HeaderSkeleton />;
  }

  return (
    <Container>
      <Info>
        <FirstRow>
          <Title>
            <Tooltip title={getStatusDesc(system)}>
              <StatusMark color={SYSTEM_STATUS_COLORS[system.status].color} />
            </Tooltip>
            {!system.monitored && (
              <Tooltip title="System is not monitored">
                <EyeOff color="#9C9CAA" size="16px" />
              </Tooltip>
            )}
            <PreTitle dimmed={!system.monitored}>#{system.number}:</PreTitle>
            <InfoTitle dimmed={!system.monitored}>{system.name}</InfoTitle>
          </Title>

          {system.project && <ProjectTitleChip iconSize="16px" target="panel" project={system.project} />}

          <FleetEditableChip systemId={system.id} value={system.profile} />
        </FirstRow>

        <SecondRow>
          {system.project ? (
            <Address value={system.addressForced} />
          ) : (
            <NotMatchtedLabel>
              <AlertTriangle size={16} />
              Not linked to Client
              <Button onClick={handleLink} variant={ButtonVariant.Flat}>
                Link
              </Button>
            </NotMatchtedLabel>
          )}
        </SecondRow>
      </Info>
    </Container>
  );
};
