import { ModalFooter, useConfirmDeleteModal } from '@common/PromiseModal';
import { Form, FormValidationRules, InputField, RadioGroupField, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useCallback, useMemo, useRef } from 'react';
import { SMART_VIEW_EMOJIES } from '@constants/emoji';
import { SmartViewFilter, SmartViewFilterType, ViewFilterScope } from '@types';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { useCreateSmartView } from '@hooks/smartViews/useCreateSmartView';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { useUpdateSmartView } from '@hooks/smartViews/useUpdateSmartView';
import { useDeleteSmartView } from '@hooks/smartViews/useDeleteSmartView';
import { useSmartViewFolderList } from '@hooks/smartViews/useSmartViewFolderList';
import { useCreateSmartViewFolder } from '@hooks/smartViews/useCreateSmartViewFolder';
import { FilterFolder } from '@generated/types/graphql';
import { useAuth } from '@hooks/useAuth';
import { AccessEntityType, AccessList, TAccessList } from '@features/Platform/AccessList';
import { Container, EmojiAndName, EmojiList } from './styled';
import { checkRoleHasSmartViewAccess } from './helpers';

type FormValues = {
  emoji: { id: string; name: string };
  name: string;
  scope: ViewFilterScope;
  folder: { id: number; name: string } | null;
};
interface Props {
  initialValues: SmartViewFilter | { type: SmartViewFilterType; conditions: Partial<SmartViewFilter['conditions']> };
  onClose: () => void;
}

const EMOJIES_OPTIONS = SMART_VIEW_EMOJIES.map((emoji) => ({ id: emoji, name: emoji }));

const ACCESS_OPTIONS = [
  {
    value: ViewFilterScope.PERSONAL,
    label: 'Keep private'
  },
  {
    value: ViewFilterScope.WORKSPACE,
    label: 'Share with the entire Workspace'
  },
  {
    value: ViewFilterScope.SHARED,
    label: 'Share with specific Users, Teams and/or Roles'
  }
];

const mapSmartViewToFormValues = (smartView: SmartViewFilter, folders: FilterFolder[]): FormValues => {
  return {
    emoji: { id: smartView.emoji, name: smartView.emoji },
    name: smartView.name,
    scope: smartView.scope,
    folder: folders.find((folder) => folder.id === smartView.filterFolderId) ?? null
  };
};

export const SmartViewForm = ({ initialValues, onClose }: Props) => {
  const isNew = !('id' in initialValues);
  const accessListRef = useRef<TAccessList>([]);

  const companyId = useAppSelector(selectWorkspaceId);

  const { user } = useAuth();
  const { data: roleAccess } = useUserRoleSettings(companyId, user.userId);

  const [currentSmartViewId, setSmartViewParam] = useQueryParam(QueryParamsEnum.SmartViewId);

  const { mutateAsync: create } = useCreateSmartView();
  const { mutateAsync: update } = useUpdateSmartView();
  const { mutateAsync: remove } = useDeleteSmartView();
  const { mutateAsync: createFolder } = useCreateSmartViewFolder();

  const confirmDelete = useConfirmDeleteModal();

  const { data: folders } = useSmartViewFolderList();

  const postForm = async (values: FormValues) => {
    let filterFolderId = values.folder?.id ?? null;

    const sharedSettings =
      values.scope === ViewFilterScope.SHARED
        ? accessListRef.current.reduce(
            (acc, item) => {
              switch (item.type) {
                case AccessEntityType.USER:
                  acc.sharedUsers.push(item.value.id);

                  break;

                case AccessEntityType.TEAM:
                  acc.sharedTeams.push(item.value.id);

                  break;
                case AccessEntityType.ROLE:
                  acc.sharedRoles.push(item.value.id);

                  break;

                default:
                  break;
              }

              return acc;
            },
            {
              sharedRoles: [] as number[],
              sharedTeams: [] as number[],
              sharedUsers: [] as number[]
            }
          )
        : {
            sharedRoles: [],
            sharedTeams: [],
            sharedUsers: []
          };

    if (values.folder?.id === 0) {
      filterFolderId = await createFolder({ name: values.folder.name });
    }
    if (isNew) {
      const createdId = await create({
        name: values.name,
        emoji: values.emoji.id,
        companyId,
        type: initialValues.type,
        filterFolderId,
        conditions: initialValues.conditions,
        scope: values.scope,
        ...sharedSettings
      });

      setSmartViewParam(createdId);
    } else {
      await update({
        id: initialValues.id,
        dto: {
          name: values.name,
          emoji: values.emoji.id,
          scope: values.scope,
          filterFolderId,
          ...sharedSettings
        }
      });
    }

    onClose();
  };

  const handleRemove = useCallback(async () => {
    if (await confirmDelete('Are you sure you want to delete this Smart View?')) {
      await remove({ id: initialValues.id });

      if (currentSmartViewId === initialValues.id.toString()) {
        setSmartViewParam(undefined);
      }
      onClose();
    }
  }, [confirmDelete, initialValues, onClose, remove, currentSmartViewId, setSmartViewParam]);

  const { handleSubmit, form } = useForm({
    onSubmit: postForm,
    defaultValues: isNew
      ? {
          emoji: { id: SMART_VIEW_EMOJIES[0], name: SMART_VIEW_EMOJIES[0] },
          name: '',
          scope: ACCESS_OPTIONS[0].value,
          folder: null
        }
      : mapSmartViewToFormValues(initialValues as SmartViewFilter, folders)
  });

  const {
    control,
    watch,
    formState: { isSubmitting }
  } = form;

  const scope = watch('scope');

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      emoji: {
        isRequired: true
      },
      name: {
        isRequired: true
      },
      scope: {
        isRequired: true
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Container>
        <EmojiAndName>
          <SelectField
            label="Emoji"
            name="emoji"
            options={EMOJIES_OPTIONS}
            getOptionLabel={(option) => option.name}
            control={control}
            isClearable={false}
            PaperComponent={EmojiList}
          />

          <InputField label="Name" name="name" control={control} />
        </EmojiAndName>

        <SelectField
          label="Hub"
          name="folder"
          getOptionLabel={(option) => option.name}
          options={folders ?? []}
          isCreatable={hasAccess(roleAccess!, 'workspace', 'create', 'smartviewFolder')}
          placeholder={
            hasAccess(roleAccess!, 'workspace', 'create', 'smartviewFolder')
              ? 'Select Hub or create new one, by default will be added to Other Hub'
              : 'Select Hub, by default will be added to Other Hub'
          }
          createButtonText={(inputValue: string) =>
            inputValue ? `+ Create "${inputValue}" as new Hub` : '+ Create new Hub'
          }
          buildNewOption={(inputValue: string) => {
            return { id: 0, name: inputValue };
          }}
          control={control}
        />
        <RadioGroupField label="Access" name="scope" options={ACCESS_OPTIONS} control={control} />
        {scope === ViewFilterScope.SHARED && (
          <AccessList
            accessEntityName="Smart views"
            checkAccessFn={checkRoleHasSmartViewAccess}
            ref={accessListRef}
            initialValue={isNew ? undefined : initialValues}
          />
        )}
      </Container>
      <ModalFooter>
        {!isNew && hasAccess(roleAccess!, 'workspace', 'delete', 'smartview') && (
          <Button onClick={handleRemove} variant={ButtonVariant.Danger}>
            Delete
          </Button>
        )}
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          {isNew ? 'Create' : 'Update'}
        </Button>
      </ModalFooter>
    </Form>
  );
};
