import { ReactQueryKey } from '@enums';
import { Blueprint } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export type WorkflowListItem = Pick<
  Blueprint,
  'id' | 'name' | 'description' | 'createdByUser' | 'createdAt' | 'type' | 'blueprintProjectStages'
>;

export const useWorkflowList = () => {
  const companyId = useSelector(selectWorkspaceId);

  return useQuery<WorkflowListItem[]>(
    [ReactQueryKey.WorkflowList],
    async () => {
      try {
        const { blueprints } = await postGraphql<{ blueprints: Blueprint[] }>(
          gql`
            query WORKFLOW_LIST_QUERY($companyId: Int!) {
              blueprints(filter: { companyId: { equalTo: $companyId } }, orderBy: CREATED_AT_DESC) {
                id
                name
                description
                type
                createdAt
                createdByUser {
                  id
                  firstName
                  lastName
                  avatarUrl
                }

                blueprintProjectStages(orderBy: [POSITION_ASC]) {
                  position
                  projectStage {
                    id
                    type
                    name
                    redSla
                    yellowSla
                  }

                  blueprintTasks {
                    taskId
                  }
                }
              }
            }
          `,
          { companyId }
        );

        return blueprints;
      } catch (e) {
        throw apiErrorHandler('Error fetching workflows', e);
      }
    },
    {
      refetchOnMount: false
    }
  );
};
