import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { Team } from '@generated/types/graphql';
import { Team as OldTeam, UpdateTeamDTO } from '@types';
import { apiErrorHandler } from '@utils';
import { useAppDispatch } from '@hooks/store';
import { alertShow } from '@state/actions/alert/alertAction';
import teamAPI from '@services/api/teamAPI';

export const useTeam = (companyId: number, teamId: number) =>
  useQuery(
    [ReactQueryKey.WorkspaceTeams, companyId, 'useTeam', teamId],
    async () => {
      return (
        await postGraphql<{ team: Team }>(
          gql`
            query TEAM_BY_ID_QUERY($teamId: Int!) {
              team(id: $teamId) {
                id
                name
                privilegeOwn
                privilegeAll
                teamUsers {
                  id
                  email
                  firstName
                  lastName
                  avatarUrl
                }
              }
            }
          `,
          { teamId }
        )
      ).team;
    },
    {
      enabled: !!companyId && !!teamId
    }
  );

export const useTeams = (companyId: number, enabled: boolean = true) =>
  useQuery(
    [ReactQueryKey.WorkspaceTeams, companyId, 'useTeams'],
    async () => {
      return (
        await postGraphql<{ teams: Team[] }>(
          gql`
            query TEAMS_QUERY($companyId: Int!) {
              teams(filter: { companyId: { equalTo: $companyId } }) {
                id
                name
                description
                privilegeOwn
                privilegeAll
                createdByUser {
                  id
                  email
                  firstName
                  lastName
                  avatarUrl
                }
                teamLead {
                  id
                  email
                  firstName
                  lastName
                  avatarUrl
                }
                teamUsers {
                  id
                  email
                  firstName
                  lastName
                  avatarUrl
                }
              }
            }
          `,
          { companyId }
        )
      ).teams;
    },
    {
      enabled: !!companyId && enabled,
      initialDataUpdatedAt: Date.now() - 1000 * 3600 * 24,
      staleTime: 1000 * 3600 * 24,
      initialData: []
    }
  );

export const useTeamsMutations = (companyId: number) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const createMutation = useMutation<OldTeam, Error, { dto: UpdateTeamDTO }>(
    async ({ dto }) => {
      try {
        return (await teamAPI.create(companyId, dto)).data;
      } catch (e) {
        throw apiErrorHandler('Error creating team', e);
      }
    },
    {
      onSuccess: (team: OldTeam) => {
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceTeams, companyId]);
        dispatch(alertShow([`Successfully created team <b>${team.name}</b> `], 'success'));
      }
    }
  );

  const updateMutation = useMutation<OldTeam, Error, { id: number; dto: UpdateTeamDTO }>(
    async ({ id, dto }) => {
      try {
        return (await teamAPI.update(companyId, id, dto)).data;
      } catch (e) {
        throw apiErrorHandler('Error updating team', e);
      }
    },
    {
      onSuccess: (team: OldTeam) => {
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceTeams, companyId]);
        dispatch(alertShow([`Successfully updated team <b>${team.name}</b> `], 'success'));
      }
    }
  );

  const deleteMutation = useMutation<void, Error, Team>(
    async (team) => {
      try {
        return await teamAPI.delete(companyId, team.id);
      } catch (e) {
        throw apiErrorHandler('Error deleteing team', e);
      }
    },
    {
      onSuccess: (_, team) => {
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceTeams, companyId]);
        dispatch(alertShow([`Successfully deleted team <b>${team.name}</b> `], 'success'));
      }
    }
  );

  return {
    create: createMutation,
    update: updateMutation,
    delete: deleteMutation
  };
};
