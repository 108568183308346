import React, { useCallback, useMemo, useState } from 'react';
import { Clipboard, Plus } from 'react-feather';
import { useSystem } from '@hooks/systems/useSystem';
import { SystemHealthWidget } from '@features/SystemPortfolio/System/Overview/SystemHealth';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useUserRoleSettings } from '@hooks/useRoles';
import { useAppSelector } from '@hooks/index';
import { selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { hasAccess } from '@utils/roles';
import { stopPropagationClick } from '@utils/utils';
import { useTaskDrawer } from '@components/Scheduler/useTaskDrawer';
import { useModal } from '@common/PromiseModal';
import { MatchToClient } from '@features/SystemPortfolio/components/MatchToClient';
import { useWorkOrderList } from '@hooks/workOrders/useWorkOrderList';
import { ReactQueryKey } from '@enums';
import { PrivilegedTasksOrderBy } from '@generated/types/graphql';
import { WorkItemList } from '@features/ProjectPortfolio/Project/Overview/Work/WorkItemList';
import { Switch } from '@kit/ui/Switch';
import { Alerts } from './Alerts';
import { Container, CreateWork, Widget, WidgetHeader, WidgetIcon, WidgetTitle, WorkPlaceholder } from './styled';
import { OverviewSkeleton } from './Skeleton';
import { NormalizedProperties } from './Properties';

interface Props {
  systemId: number;
}

export const Overview = ({ systemId }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const userId = useAppSelector(selectCurrentUserId);

  const [isArchivedWorkShown, setIsArchivedWorkShown] = useState(false);

  const { data: system, isLoading } = useSystem(systemId);

  const { data: workOrders = [] } = useWorkOrderList({
    queryKey: ReactQueryKey.SystemWork,
    filters: { systemId: { equalTo: systemId }, isArchived: isArchivedWorkShown ? {} : { equalTo: false } },
    orderBy: [
      PrivilegedTasksOrderBy.IsArchivedAsc,
      PrivilegedTasksOrderBy.PriorityAsc,
      PrivilegedTasksOrderBy.CreatedAtAsc
    ]
  });

  const work = useMemo(
    () => workOrders.filter((workOrder) => isArchivedWorkShown || !workOrder.isArchived),
    [workOrders, isArchivedWorkShown]
  );

  const { data: access, isFetching: isFetchingAcesss } = useUserRoleSettings(companyId, userId);

  const canViewWork = hasAccess(access, 'task', 'view');

  const { onAddClick } = useTaskDrawer();

  const { openModal } = useModal();

  const handleLink = useCallback(() => {
    openModal<void>(({ onClose }) => <MatchToClient system={system} onClose={onClose} />, {
      title: `Link #${system.number}: ${system.name} to Client`
    });
  }, [openModal, system]);

  const handleCreateWorkOrderClick = useCallback(() => {
    onAddClick({
      project: system.project,
      system
    });
  }, [onAddClick, system]);

  if (isLoading || isFetchingAcesss) {
    return <OverviewSkeleton />;
  }

  if (!system) {
    return null;
  }

  const isLinkedWithClient = Boolean(system.project);

  const canCreateWork = isLinkedWithClient && hasAccess(access, 'task', 'create');

  return (
    <Container isOneColumn>
      <Alerts
        systemId={system.id}
        alerts={system.systemAlertsBySystemIdConnection.nodes}
        onCreateWorkOrder={canCreateWork ? handleCreateWorkOrderClick : undefined}
      />
      <SystemHealthWidget system={system} />
      <NormalizedProperties system={system} />

      {canViewWork && (
        <Widget isFullWidth size="large">
          <WidgetHeader>
            <WidgetTitle>
              <WidgetIcon backgroundColor="#FCF2DB">
                <Clipboard size="16px" color="#F1AA12" />
              </WidgetIcon>
              Work
              {canCreateWork && (
                <CreateWork {...stopPropagationClick}>
                  <Button onClick={handleCreateWorkOrderClick} variant={ButtonVariant.Secondary}>
                    <Plus size="16px" />
                    Work Order
                  </Button>
                </CreateWork>
              )}
            </WidgetTitle>

            <Switch
              isActive={isArchivedWorkShown}
              id="show_archived_work"
              label="Show archived"
              onChange={setIsArchivedWorkShown}
            />
          </WidgetHeader>

          {isLinkedWithClient && (
            <>
              {work.length === 0 && <WorkPlaceholder>No work yet</WorkPlaceholder>}
              {work.length > 0 && <WorkItemList work={work} />}
            </>
          )}

          {!isLinkedWithClient && (
            <WorkPlaceholder>
              <div>You cannot create Work Orders for Systems that are not linked with Clients.</div>
              <div>
                <Button onClick={handleLink} variant={ButtonVariant.Flat}>
                  Link to Client
                </Button>
              </div>
            </WorkPlaceholder>
          )}
        </Widget>
      )}
    </Container>
  );
};
