import { Link } from '@reach/router';
import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const Scrollable = styled.div`
  overflow: auto;
  height: calc(100vh - 48px - 24px - 24px - 34px - 24px);
  ${scroll};
`;

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  background-color: #fff;

  thead {
    position: sticky;
    top: 82px;
    z-index: 2;

    background-color: #f8fafb;
  }

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 48px;
    text-align: left;
    padding-left: 16px;
    border-top: 1px solid #f8fafb;
    border-bottom: 1px solid #dfdfe8;

    font-size: 12px;
    font-weight: 500;
    color: #828d9a;
    text-transform: uppercase;

    :first-child {
      width: 54px;
      min-width: 54px;
      max-width: 54px;
      border-left: 2px solid #f8fafb;
    }

    :last-child {
      text-align: right;
      border-right: 1px solid #f8fafb;
    }
  }

  tbody {
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 64px;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px solid #dfdfe8;
    padding: 8px 16px;
    position: relative;

    :first-child {
      width: 54px;
      min-width: 54px;
      max-width: 54px;
      border-left: 1px solid #dfdfe8;
    }

    &:last-child {
      border-right: 1px solid #dfdfe8;
    }
  }

  tr:first-child {
    td {
      border-top: 0;
    }
  }

  td.title {
    width: 232px;
    min-width: 232px;
    max-width: 232px;
    > * {
      display: flex;
      align-items: center;
      gap: 16px;

      svg {
        flex-shrink: 0;
      }
    }
  }

  td.content {
    > * {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  td.time {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }

  td.project {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }
`;

export const Row = styled.tr<{ isSelected: boolean; isRead: boolean }>`
  background-color: ${({ isSelected }) => {
    if (isSelected) {
      return '#E3F6F4';
    }

    return '#fff';
  }};

  cursor: pointer;

  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }

  > td,
  td > * {
    font-weight: ${({ isRead }) => (isRead ? undefined : 500)};
  }
`;

export const SelectionControlPanel = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  svg {
    cursor: pointer;
  }
`;

export const SelectedLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.black};
  text-transform: none;
`;

export const BulkActionButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  color: ${colors.green};
`;

export const PlaceholderBlock = styled.div`
  margin-top: 66px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  img {
    max-height: 200px;
    margin-bottom: 24px;
  }
`;

export const PlaceholderTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

export const PlaceholderDescription = styled.div`
  color: #828d9a;
  font-size: 20px;
  font-weight: 400;
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const EntityChip = styled(Link)`
  color: #235dff;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: 12px;
  gap: 4px;

  > div {
    max-width: 168px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const EmailBody = styled.span`
  color: #828d9a;
  font-weight: 400 !important;
`;
