import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import tasksApi from '@services/api/tasksApi';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';

type UpdateWorkOrderPayload = {
  id: number;
  projectId: number;
  projectStageId: number;
};

export const useUpdateWorkOrder = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<void, Error, UpdateWorkOrderPayload>(
    async (req) => {
      try {
        await tasksApi.updateTask({
          ...req
        });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Work Order updated successfully');
        queryClient.invalidateQueries([ReactQueryKey.Tasks]);
      }
    }
  );
};
