import React, { KeyboardEventHandler, useCallback, useState } from 'react';
import { SlidePanel } from '@common/SlidePanel';
import { XIcon } from '@kit/ui/icons/X';
import { colors } from '@styles';
import { Project } from '@features/ProjectPortfolio/Project';
import { WorkOrderContainer } from '@components/Scheduler/components/WorkOrderView/WorkOrderContainer';
import { FastForward, Maximize2, Minimize2, Rewind } from 'react-feather';
import { Client } from '@features/ClientPortfolio/Client';
import { Request } from '@features/RequestPortfolio/Request';
import { FileContainer } from '@components/Project/DataBank/DataBankContent/FileList/FileProperties/FileProperties';
import { CurrentNotification } from '@features/Platform/Inbox/CurrentNotification';
import { useKeyboardEvent } from '@react-hookz/web';
import { ActionTemplateFormContainer } from '@features/Platform/Templates/ActionTemplateForm';
import { ActionItemViewContainer } from '@features/Work/ActionItemView';
import { System } from '@features/SystemPortfolio/System';
import { ArrowButton, CloseButton, OuterButtons } from './styled';
import { DrawerEntity, DrawerState } from './types';
import { extractEntityIdFromUrl } from './helpers';

const WIDTH_BY_DRAWER_TYPE: Record<DrawerEntity, number> = {
  [DrawerEntity.CLIENT]: 85,
  [DrawerEntity.PROJECT]: 85,
  [DrawerEntity.REQUEST]: 85,
  [DrawerEntity.WORK_ORDER]: 75,
  [DrawerEntity.DOC]: 75,
  [DrawerEntity.FILE]: 75,
  [DrawerEntity.NOTIFICATION]: 85,
  [DrawerEntity.ACTION_ITEM_TEMPLATE]: 40,
  [DrawerEntity.ACTION_ITEM]: 40,
  [DrawerEntity.SYSTEM]: 85
};

const NAV_AND_FS_BUTTON_HIDDEN_TYPES = [DrawerEntity.ACTION_ITEM_TEMPLATE];

const DrawerContent = ({
  drawer,
  isFullScreen,
  parentId
}: {
  drawer: DrawerState;
  isFullScreen: boolean;
  parentId?: number;
}) => {
  const entityId = extractEntityIdFromUrl(window.location.pathname, drawer.type);
  switch (drawer.type) {
    case DrawerEntity.CLIENT:
      return <Client clientId={entityId} />;
    case DrawerEntity.REQUEST:
      return <Request requestId={entityId} />;
    case DrawerEntity.PROJECT:
      return <Project projectID={entityId} />;
    case DrawerEntity.WORK_ORDER:
      return <WorkOrderContainer id={entityId} />;
    case DrawerEntity.DOC:
      return <FileContainer id={entityId} isFullScreen={isFullScreen} />;
    case DrawerEntity.FILE:
      return <FileContainer parentDocId={parentId} id={entityId} isFullScreen={isFullScreen} />;
    case DrawerEntity.NOTIFICATION:
      return <CurrentNotification id={entityId} isFullScreen={isFullScreen} />;
    case DrawerEntity.ACTION_ITEM_TEMPLATE:
      return <ActionTemplateFormContainer id={entityId} />;
    case DrawerEntity.ACTION_ITEM:
      return <ActionItemViewContainer id={entityId} />;
    case DrawerEntity.SYSTEM:
      return <System systemId={entityId} />;
    default:
      return null;
  }
};

export const Drawer = ({
  drawer,
  zIndexIncrement,
  isButtonsVisible,
  onUpdateCurrentId,
  onClose,
  index,
  parentId
}: {
  drawer: DrawerState;
  zIndexIncrement: number;
  isButtonsVisible: boolean;
  onUpdateCurrentId: (newCurrentId: number, drawerIndex: number) => void;
  index: number;
  onClose: (index: number) => void;
  parentId?: number;
}) => {
  const { type, listItemIds, currentId } = drawer;

  const [isFullScreen, setIsFullScreen] = useState(false);

  const hasPrevious = listItemIds.indexOf(currentId) > 0;
  const hasNext = listItemIds.indexOf(currentId) < listItemIds.length - 1;

  const handleNextClick = useCallback<KeyboardEventHandler<EventTarget>>(
    (e) => {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement ||
        e.target.isContentEditable
      ) {
        return;
      }
      if (!hasNext || !isButtonsVisible) {
        return;
      }

      onUpdateCurrentId(listItemIds[listItemIds.indexOf(currentId) + 1], index);
    },
    [onUpdateCurrentId, listItemIds, currentId, index, hasNext, isButtonsVisible]
  );

  const handlePrevClick = useCallback<KeyboardEventHandler<EventTarget>>(
    (e) => {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement ||
        e.target.isContentEditable
      ) {
        return;
      }

      if (!hasPrevious || !isButtonsVisible) {
        return;
      }

      onUpdateCurrentId(listItemIds[listItemIds.indexOf(currentId) - 1], index);
    },
    [onUpdateCurrentId, listItemIds, currentId, index, hasPrevious, isButtonsVisible]
  );

  useKeyboardEvent('ArrowRight', handleNextClick, [handleNextClick]);
  useKeyboardEvent('ArrowLeft', handlePrevClick, [handlePrevClick]);

  const handleClose = useCallback(() => {
    onClose(index);
  }, [onClose, index]);

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen((prev) => !prev);
  }, []);

  const isNavAndFsShown = !NAV_AND_FS_BUTTON_HIDDEN_TYPES.includes(type);

  return (
    <SlidePanel width={`${WIDTH_BY_DRAWER_TYPE[type]}%`} zIndexIncrement={zIndexIncrement} isFullScreen={isFullScreen}>
      {isButtonsVisible && (
        <OuterButtons isRow={isFullScreen}>
          <CloseButton className="close" onClick={handleClose}>
            <XIcon size="24px" />
          </CloseButton>
          {isNavAndFsShown && (
            <>
              <ArrowButton isDisabled={false} onClick={toggleFullScreen}>
                {isFullScreen ? (
                  <Minimize2 size="16px" color={colors.green} />
                ) : (
                  <Maximize2 size="16px" color={colors.green} />
                )}
              </ArrowButton>
              <div className="prev" onClick={hasPrevious ? handlePrevClick : undefined}>
                <ArrowButton isDisabled={!hasPrevious}>
                  <Rewind size="16px" color={hasPrevious ? colors.green : colors.gray} />
                </ArrowButton>
              </div>
              <div className="next" onClick={hasNext ? handleNextClick : undefined}>
                <ArrowButton isDisabled={!hasNext}>
                  <FastForward size="16px" color={hasNext ? colors.green : colors.gray} />
                </ArrowButton>
              </div>
            </>
          )}
        </OuterButtons>
      )}

      <DrawerContent drawer={drawer} parentId={parentId} isFullScreen={isFullScreen} />
    </SlidePanel>
  );
};

interface Props {
  drawers: DrawerState[];
  onUpdateDrawerCurrentId: (newCurrentId: number, drawerIndex: number) => void;
  onDrawerClose: () => void;
}

export const Drawers = ({ drawers, onUpdateDrawerCurrentId, onDrawerClose }: Props) => {
  return (
    <>
      {drawers.map((drawer, index) => (
        <Drawer
          key={drawer.type}
          isButtonsVisible={index === drawers.length - 1}
          zIndexIncrement={index * 5}
          drawer={drawer}
          index={index}
          onUpdateCurrentId={onUpdateDrawerCurrentId}
          onClose={onDrawerClose}
          parentId={drawers[index - 1]?.currentId}
        />
      ))}
    </>
  );
};
