import { useRecordDetail } from '@hooks/useRecordDetail';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ActionItemListItem } from '@hooks/actionItems/useActionItemList';
import { PrivilegedTask, ProjectStage } from '@generated/types/graphql';
import { Collapse } from '@material-ui/core';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder, OnDragStartResponder } from 'react-beautiful-dnd';
import { useUpdateWorkOrder } from '@hooks/workOrders/useUpdateWorkOrder';
import { useUpdateActionItem } from '@hooks/actionItems/useUpdateActionItem';
import { SearchBar } from '@common/SearchBar';
import { ActionItemRow, WorkOrderRow } from './WorkItemList';
import { DraggableRow, SearchContainer, SectionContainer, Stage, StageName, WorkListContainer } from './styled';
import { useWorkGroupedByStage } from './useWork';
import { getStageStatus } from './helpers';
import { STAGE_STATUS_CONFIG } from './constants';

interface Props {
  projectId: number;
  isArchivedShown: boolean;
}

export const WorkByStages = ({ projectId, isArchivedShown }: Props) => {
  const { data: record } = useRecordDetail(projectId, { refetchOnMount: false });
  const workByStageId = useWorkGroupedByStage(projectId, isArchivedShown);
  const [currentDraggingId, setCurrentDraggingId] = useState<string | null>(null);
  const { mutateAsync: updateWorkOrder } = useUpdateWorkOrder();
  const { mutateAsync: updateActionItem } = useUpdateActionItem();
  const [expandedStageId, setExpandedStageId] = useState<number | null>(record?.stage?.id ?? -1);
  const [search, setSearch] = useState('');

  const filteredWork = useMemo(() => {
    if (!search.trim()) {
      return workByStageId;
    }

    return Object.fromEntries(
      Object.entries(workByStageId).map(([stageId, work]) => {
        return [
          stageId,
          work.filter(
            (item) =>
              item.title.toLowerCase().includes(search.toLowerCase()) ||
              ('uid' in item && `#${item.uid}`.includes(search.toLowerCase()))
          )
        ];
      })
    );
  }, [workByStageId, search]);

  const handleStageClick = useCallback((id: number) => {
    setExpandedStageId((prev) => (prev === id ? null : id));
  }, []);

  const handleDragStart = useCallback<OnDragStartResponder>(({ draggableId }) => {
    setCurrentDraggingId(draggableId);
  }, []);

  const handleDragEnd = useCallback<OnDragEndResponder>(
    async ({ source, destination, reason, draggableId }) => {
      setCurrentDraggingId(null);

      if (reason !== 'DROP') {
        return;
      }

      if (!destination) {
        return;
      }

      const sourceGroupId = parseInt(source.droppableId.replace('droppableSection-', ''), 10);
      const destinationGroupId = parseInt(destination.droppableId.replace('droppableSection-', ''), 10);

      if (sourceGroupId === destinationGroupId) {
        return;
      }

      const isActionItem = draggableId.startsWith('ai-');
      const workId = parseInt(draggableId.replace(/(ai-|wo-)/g, ''), 10);

      if (isActionItem) {
        await updateActionItem({
          id: workId,
          dto: {
            projectStageId: destinationGroupId
          }
        });
      } else {
        await updateWorkOrder({
          id: workId,
          projectId,
          projectStageId: destinationGroupId
        });
      }
    },
    [updateWorkOrder, updateActionItem, projectId]
  );

  if (!record || !record.blueprint) {
    return null;
  }

  return (
    <div>
      <SearchContainer>
        <SearchBar placeholder="Search for Work Orders..." onValueChange={setSearch} />
      </SearchContainer>
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <div>
          {record.blueprint.blueprintProjectStages.map(({ projectStage }, index) => {
            const timelineStage = record.projectStageTimelinesByProjectId.find(
              (timelineItem) => timelineItem.stage.id === projectStage.id
            );
            const isEndedInTimeline = Boolean(timelineStage?.isEnded);
            const isStartedInTimeline = Boolean(timelineStage);

            if (!filteredWork[projectStage.id]?.length && search.length) {
              return null;
            }

            return (
              <Droppable key={projectStage.id} droppableId={`droppableSection-${projectStage.id}`}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <StageWork
                      index={index}
                      isDraggingOver={snapshot.isDraggingOver}
                      currentlyDraggingId={currentDraggingId}
                      work={filteredWork[projectStage.id] ?? []}
                      stage={projectStage}
                      isExpanded={search || expandedStageId === projectStage.id}
                      onClick={handleStageClick}
                      isCurrent={record.stage?.id === projectStage.id}
                      isEndedInTimeline={isEndedInTimeline}
                      isStartedInTimeline={isStartedInTimeline}
                    />
                  </div>
                )}
              </Droppable>
            );
          })}

          {filteredWork[-1] && (
            <Droppable droppableId="droppableSection--1" isDropDisabled>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <StageWork
                    index={record.blueprint.blueprintProjectStages.length}
                    work={filteredWork[-1]}
                    isDraggingOver={false}
                    currentlyDraggingId={currentDraggingId}
                    stage={{ id: -1, name: 'Other' }}
                    isExpanded={expandedStageId === -1}
                    onClick={handleStageClick}
                    isCurrent={!record.stage?.id}
                    isEndedInTimeline={false}
                    isStartedInTimeline={false}
                  />
                </div>
              )}
            </Droppable>
          )}
        </div>
      </DragDropContext>
    </div>
  );
};

interface StageWorkProps {
  index: number;
  work: (ActionItemListItem | PrivilegedTask)[];
  stage: ProjectStage;
  isExpanded: boolean;
  onClick: (stageId: number) => void;
  isCurrent: boolean;
  isEndedInTimeline: boolean;
  isStartedInTimeline: boolean;
  isDraggingOver?: boolean;
  currentlyDraggingId: string | null;
}

const StageWork = ({
  isCurrent,
  isStartedInTimeline,
  isEndedInTimeline,
  index,
  work,
  stage,
  isExpanded,
  onClick,
  isDraggingOver,
  currentlyDraggingId
}: StageWorkProps) => {
  const isExpandable = work.length > 0;

  const handleClick = useCallback(() => {
    if (!isExpandable) {
      return;
    }

    onClick(stage.id);
  }, [onClick, stage.id, isExpandable]);

  const stageStatus = useMemo(
    () =>
      getStageStatus({
        work,
        isCurrent,
        isEndedInTimeline,
        isStartedInTimeline
      }),
    [isCurrent, isStartedInTimeline, isEndedInTimeline, work]
  );

  const statusConfig = STAGE_STATUS_CONFIG[stageStatus];

  return (
    <SectionContainer isDraggingOver={isDraggingOver}>
      <Stage isExpandable={isExpandable} onClick={handleClick}>
        <StageName>
          {index + 1}. {stage.name}
          {isExpandable &&
            (isExpanded ? (
              <ChevronUpIcon size="24px" color="#9C9CAA" />
            ) : (
              <ChevronDownIcon size="24px" color="#9C9CAA" />
            ))}
        </StageName>

        <Badge size={BadgeSize.Large} color={statusConfig.color} bgColor={statusConfig.bgColor}>
          {statusConfig.text.toUpperCase()}
        </Badge>
      </Stage>

      <Collapse in={isExpanded}>
        <WorkListContainer>
          {work.map((workItem, index) => {
            const isAtionItem = 'actionAssignees' in workItem;
            const key = `${isAtionItem ? 'ai' : 'wo'}-${workItem.id}`;

            return (
              <Draggable key={key} draggableId={key} index={index}>
                {(provided, snapshot) => (
                  <>
                    <DraggableRow
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={provided.draggableProps.style}
                      isDragging={snapshot.isDragging}
                      isCloneOfDragging={!snapshot.isDragging && currentlyDraggingId === key}
                    >
                      {isAtionItem ? <ActionItemRow actionItem={workItem} /> : <WorkOrderRow workOrder={workItem} />}
                    </DraggableRow>

                    {snapshot.isDragging && (
                      <DraggableRow isDragging={false} isCloneOfDragging>
                        {isAtionItem ? <ActionItemRow actionItem={workItem} /> : <WorkOrderRow workOrder={workItem} />}
                      </DraggableRow>
                    )}
                  </>
                )}
              </Draggable>
            );
          })}
        </WorkListContainer>
      </Collapse>
    </SectionContainer>
  );
};
