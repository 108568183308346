import { colors } from '@styles';
import styled from 'styled-components';

export const Container = styled.div<{ maxWidth: string }>`
  display: inline-flex;
  gap: 8px;
  align-items: center;

  .copy {
    margin-left: 0;
    opacity: 0;
    color: ${colors.gray2};
    transition: opacity 0.15s ease-in-out;
  }

  &:hover .copy {
    opacity: 1;
  }

  a {
    overflow: hidden;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
    gap: 4px;
    > div {
      max-width: ${({ maxWidth }) => maxWidth};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    svg {
      flex-shrink: 0;
    }

    display: inline-flex;
    color: #235dff;
    font-size: 14px;
  }
`;
