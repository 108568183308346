import React, { useMemo } from 'react';
import { ArrowRight, Clock } from 'react-feather';
import { RecordType } from '@types';
import { ReminderEventType } from '@enums';
import { formatDate } from '@utils/dates';
import { BaseItemTemplate } from './BaseItemTemplate';
import { FeedItemProps } from './types';
import { FeedItemIconContainer, OldValue, ValuesWithArrow } from './styled';

export const ReminderFeedItem = ({ item, context }: FeedItemProps) => {
  const { title, children } = useMemo(() => {
    const entityName =
      item.payload?.isAppointment ?? item.payload?.project?.type === RecordType.DEAL ? 'Appointment' : 'Reminder';
    const assigneeName = item.payload?.assignee?.name;
    switch (item.eventType) {
      case ReminderEventType.ASSIGNED_TO_ME: {
        return {
          title: (
            <>
              {entityName} <b>{item.payload.name}</b> created for {item.payload?.assignee?.name}
            </>
          ),
          children: null
        };
      }
      case ReminderEventType.DUE_DATE:
        return {
          title: (
            <>
              {entityName} <b>{item.payload.name}</b> has reached it&apos;s deadline
            </>
          ),
          children: null
        };
      case ReminderEventType.RESCHEDULED: {
        const isRescheduled = Boolean(item.payload?.previousDueDate);
        const isRemoved = Boolean(item.payload?.deleted);
        const { previousDueDate, dueDate } = item.payload ?? {};

        if (isRemoved) {
          return {
            title: (
              <>
                {entityName} <b>{item.payload.name}</b> was cancelled
              </>
            ),
            children: null
          };
        }

        // If the reminder was rescheduled to the same date, we don't need to show the feed item
        // BE should fix this in ENG-1179, but for now we can just ignore it
        if (previousDueDate === dueDate) {
          return {
            title: null,
            children: null
          };
        }

        return {
          title: (
            <>
              {entityName} <b>{item.payload.name}</b> has been {isRescheduled ? 're' : ''}scheduled{' '}
              {assigneeName ? `for ${assigneeName}` : ''}
            </>
          ),
          children: (
            <div>
              <ValuesWithArrow isVertical={false}>
                <OldValue>
                  <div>{formatDate(previousDueDate, 'MM/DD/YY hh:mm A')}</div>
                </OldValue>
                <ArrowRight size="16px" />
                <div>{formatDate(dueDate, 'MM/DD/YY hh:mm A')}</div>
              </ValuesWithArrow>
            </div>
          )
        };
      }

      default:
        return {
          title: null,
          children: null
        };
    }
  }, [item]);

  if (!title && !children) {
    return null;
  }

  return (
    <BaseItemTemplate
      context={context}
      id={item.id}
      icon={
        <FeedItemIconContainer color="#FCF2DB">
          <Clock color="#F1AA12" size="12px" />
        </FeedItemIconContainer>
      }
      date={item.createdAt}
      title={title}
      author={item.createdBy}
    >
      {children}
    </BaseItemTemplate>
  );
};
