import { isDefaultPropertiesGroup, usePropertyGroups } from '@hooks/usePropertyGroup';
import { RecordType } from '@types';
import React, { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { PropertyValueField } from '@features/Platform/Properties/PropertyValueField';
import { ProjectColumnVisibility } from '@generated/types/graphql';
import { Grid, SectionTitle } from './styled';

interface Props {
  type: RecordType;
  control: Control<any>;
}

export const CustomFields = ({ type, control }: Props) => {
  const { data: allGroups } = usePropertyGroups({ scope: type });

  const groups = useMemo(() => {
    if (!allGroups) {
      return [];
    }

    return allGroups
      .filter((group) => !isDefaultPropertiesGroup(group))
      .map((group) => {
        return {
          ...group,
          properties: group.properties.filter(
            (property) => property.isAdditional && property.internalVisibility !== ProjectColumnVisibility.Hidden
          )
        };
      })
      .filter((group) => group.properties.length > 0);
  }, [allGroups]);

  return (
    <>
      {groups.map((group) => {
        return (
          <div key={group.id}>
            <SectionTitle>{group.name}</SectionTitle>
            <Grid>
              {group.properties.map((property) => {
                return (
                  <PropertyValueField
                    key={property.id}
                    label={property.name}
                    name={property.isAdditional ? `additional.${property.id}` : property.mappedName}
                    property={property}
                    control={control}
                  />
                );
              })}
            </Grid>
          </div>
        );
      })}
    </>
  );
};
