import { ActionStatus, PrivilegedTask, TaskStatus } from '@generated/types/graphql';
import { ActionItemListItem } from '@hooks/actionItems/useActionItemList';
import { StageStatus } from './types';

export const getStageStatus = ({
  work,
  isCurrent: _isCurrent,
  isEndedInTimeline: _isEndedInTimeline,
  isStartedInTimeline: _isStartedInTimeline
}: {
  work: (ActionItemListItem | PrivilegedTask)[];
  isCurrent: boolean;
  isEndedInTimeline: boolean;
  isStartedInTimeline: boolean;
}) => {
  const notArchivedWork = work.filter((workItem) => !('taskStatus' in workItem) || !workItem.isArchived);

  const isAllWorkFinished =
    notArchivedWork.length > 0 &&
    notArchivedWork.every(
      (workItem) =>
        ('taskStatus' in workItem && [TaskStatus.Completed, TaskStatus.Cancelled].includes(workItem.taskStatus.id)) ||
        ('actionAssignees' in workItem && [ActionStatus.Completed, ActionStatus.Cancelled].includes(workItem.status))
    );

  const isSomeWorkInProgress = notArchivedWork.some(
    (workItem) =>
      ('taskStatus' in workItem &&
        ![TaskStatus.Queued, TaskStatus.Assigned, TaskStatus.Cancelled].includes(workItem.taskStatus.id)) ||
      ('actionAssignees' in workItem && ![ActionStatus.Completed, ActionStatus.Cancelled].includes(workItem.status))
  );

  if (isAllWorkFinished) {
    return StageStatus.COMPLETED;
  }

  if (isSomeWorkInProgress) {
    return StageStatus.IN_PROGRESS;
  }

  return StageStatus.NOT_STARTED;
};
