import { ReactQueryKey } from '@enums';
import { AnalyticsModule, DrilldownFilter, WidgetSettings } from '@features/Analytics/types';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useInfiniteQuery } from 'react-query';
import { fetchWorkOrdersDataPreview } from './workOrders';
import { DataPreview, WidgetDataPreviewParams } from './types';
import { WidgetSettingsError } from '../WidgetSettingsError';
import { fetchRecordsDataPreview } from './records';
import { usePropertiesMap } from '../hooks';
import { useStageIdsByWorkflowMap } from '../hooks/useStageIdsByWorkflowMap';
import { useTeamsMap } from '../hooks/useTeamsMap';

const PER_PAGE = 10;

const fetchDataPreview = async <T, TFilterType, TConditionType>(
  params: WidgetDataPreviewParams<TFilterType, TConditionType>
): Promise<T> => {
  switch (params.settings.module) {
    case AnalyticsModule.CLIENTS:
    case AnalyticsModule.PROJECTS:
    case AnalyticsModule.REQUESTS:
      return fetchRecordsDataPreview(params) as Promise<T>;
    case AnalyticsModule.WORK_ORDERS:
      return fetchWorkOrdersDataPreview(params) as Promise<T>;
    default:
      throw new WidgetSettingsError('Invalid module type');
  }
};

export const useWidgetDataPreview = <T, TFilterType, TConditionType>(
  settings: WidgetSettings,
  drilldownFilter?: DrilldownFilter<TFilterType, TConditionType>
) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { propertiesMap, isPropertiesLoading } = usePropertiesMap(settings.module);
  const { stageIdsByWorkflowMap, isWorkflowsLoading } = useStageIdsByWorkflowMap();
  const { teamsMap } = useTeamsMap();

  return useInfiniteQuery<DataPreview<T>>(
    [ReactQueryKey.AnalyticsDataPreview, companyId, { ...settings }, drilldownFilter],
    async ({ pageParam = 0 }) => {
      return fetchDataPreview<DataPreview<T>, TFilterType, TConditionType>({
        companyId,
        settings,
        page: pageParam,
        perPage: PER_PAGE,
        propertiesMap,
        drilldownFilter,
        stageIdsByWorkflowMap,
        teamsMap
      });
    },
    {
      keepPreviousData: false,
      getNextPageParam: (lastPage) => {
        return lastPage.reports.length === PER_PAGE ? lastPage.page + 1 : undefined;
      },
      refetchOnMount: false,
      enabled: !isPropertiesLoading && !isWorkflowsLoading // TODO validate settings
    }
  );
};
