import { Input } from '@kit/ui/Input';
import { colors, device } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 16px;
  border-bottom: 1px solid #dfdfe8;
  gap: 16px;

  svg {
    flex-shrink: 0;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
`;

export const Image = styled.div<{ isUpdating: boolean }>`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 88px;
  width: 88px;
  position: relative;
  border-radius: 8px;

  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 88px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    opacity: ${({ isUpdating }) => (isUpdating ? 1 : 0)};
    transition: opacity 0.15s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    transition: background-color 0.15s ease-in-out;

  }

  :hover { 
    cursor: pointer;

    &:after {
      background-color: rgba(29, 29, 53, 0.5);
    }

    svg {
      opacity: 1;
      z-index: 1;
    }
  }

}

`;

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const CardInfoBlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  gap: 16px;

  > div {
    color: #98a9bc;
    transition: color 0.15s ease-in-out;

    &:hover {
      color: ${colors.green};
    }
  }
`;

export const TitleAndStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;

  svg {
    flex-shrink: 0;
  }
`;

export const InfoTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1d1d35;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PreTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1d1d35;
  white-space: nowrap;
`;

export const EditableWrapper = styled.div<{ isEditable: boolean }>`
  padding: 4px;
  border: 1px solid transparent;
  width: 100%;
  min-width: 100px;
  transition:
    border-color 0.15s ease-in-out,
    background-color 0.15s ease-in-out;

  border-radius: 4px;

  ${({ isEditable }) =>
    isEditable &&
    `
  cursor: pointer;

  &:hover {
    border: 1px solid #c8c8d3;

    background-color: ${colors.white};
  }
    `};

  svg {
    flex-shrink: 0;
  }
`;

export const NameInput = styled(Input)`

&& {
.MuiOutlinedInput-input {
    font-size: 20px;
    line-height: 24px;

    font-weight: 500;
    padding: 5px;
    height: auto;
    letter-spacing normal;
    min-width: 100px;
    max-width: 100%;
    field-sizing: content;
}
}
`;

export const ProjectAccountInfoChip = styled.div<{ isEditable: boolean; isClickable?: boolean }>`
  border: 1px solid #dfdfe8;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  cursor: ${({ isClickable, isEditable }) => (isClickable || isEditable ? 'pointer' : 'default')};
  color: ${({ isClickable }) => (isClickable ? '#009688' : '#1d1d35')};
  background-color: ${({ isEditable, isClickable }) => {
    return isEditable || isClickable ? '#FFF' : '#F7F7FC';
  }};

  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  height: 24px;

  & svg {
    margin-right: 4px;
  }

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }

  ${device.md`
  max-width: 165px;
  `}
`;
