import React, { useEffect, useRef } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useClickOutside } from '@react-hookz/web';
import { noop, set } from 'lodash';
import { FormField } from '../FormField';
import { FormControl, FormInputPropsToOmit } from '../types';
import { useControllerWithValidation } from '../useControllerWithValidation';
import { useInlineEditableField } from './useInlineEditableField';
import { RichEditor } from '../fields/RichTextField/styled';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof RichEditor>, FormInputPropsToOmit>,
    FormControl<TFieldValues, TName> {
  isToolbarShown?: boolean;
  isToolbarHiddenCompletely?: boolean;
  isOneLine?: boolean;
  renderView: (value: string, onClick: () => void) => React.ReactNode;
  onChanged?: () => void;
}

export const InlineEditableRichTextField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  clearOnUnmount,
  renderView,
  onChanged,
  isToolbarShown = true,
  isToolbarHiddenCompletely = false,
  isOneLine = false,
  onBlur,
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const { isEditMode, onEnterEditMode, onExitEditMode } = useInlineEditableField(onChanged);

  const editorRef = useRef();
  const editorContainerNodeRef = useRef();

  useClickOutside(editorContainerNodeRef, () => {
    onBlur?.();
    onExitEditMode();
  });

  useEffect(() => {
    if (!isOneLine) {
      return;
    }
    let editorInstance: any = null;
    try {
      editorInstance = editorRef.current?.getEditor();
    } catch {
      editorInstance = null;
    }
    set(editorInstance, 'keyboard.bindings[13][5].handler', noop);
  }, [isOneLine]);

  const {
    field: { ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      {!isEditMode && renderView(controlProps.value as string, onEnterEditMode)}
      {isEditMode && (
        <div ref={editorContainerNodeRef}>
          <RichEditor
            isOneLine={isOneLine}
            hideToolbar={!isToolbarShown}
            hideToolbarCompletly={isToolbarHiddenCompletely}
            editorRef={editorRef}
            {...controlProps}
            {...inputProps}
            data-test-id={`field-${controlProps.name}`}
            formikMode
          />
        </div>
      )}
    </FormField>
  );
};
