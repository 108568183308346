import { SystemFilter, SystemGroupBy, SystemsOrderBy } from '@generated/types/graphql';
import { DateTime, Duration, Interval } from 'luxon';
import { DeepPartial } from 'redux';
import { DateTimeUnit } from 'luxon/src/datetime';

export enum GroupField {
  status = 'status'
}

export const groupToExpr = (field: GroupField = null): SystemGroupBy[] => {
  switch (field) {
    case null:
      return [];
    case GroupField.status:
      return [SystemGroupBy.Status];
    default:
      throw new Error(field satisfies never);
  }
};

export enum SortField {
  id = 'id',
  name = 'name',
  operationalAt = 'operationalAt',
  number = 'number'
}

export const sortToExpr = (sortBy: [field: SortField, desc?: boolean][] = []): SystemsOrderBy[] => {
  const orderByExpr: SystemsOrderBy[] = sortBy.map(([field, desc]) => {
    switch (field) {
      case SortField.id:
        return desc ? SystemsOrderBy.IdDesc : SystemsOrderBy.IdAsc;
      case SortField.name:
        return desc ? SystemsOrderBy.NameDesc : SystemsOrderBy.NameAsc;
      case SortField.operationalAt:
        return desc ? SystemsOrderBy.OperationalAtDesc : SystemsOrderBy.OperationalAtAsc;
      case SortField.number:
        return desc ? SystemsOrderBy.NumberDesc : SystemsOrderBy.NumberAsc;
      default:
        throw new Error(field satisfies never);
    }
  });

  orderByExpr.push(SystemsOrderBy.IdAsc);

  return orderByExpr;
};

export const queryToExpr = (query: string): DeepPartial<SystemFilter> =>
  query
    ? {
        or: [
          { number: { equalTo: +query } },
          { providerId: { equalTo: query } },
          { name: { includesInsensitive: query } }
        ]
      }
    : {};

/**
 * Set of moving periods for widgets with system(s) graphs and performance.
 */
export enum ChartPeriod {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
  lifetime = 'lifetime'
}

export const chartPeriodConfig: {
  [period in ChartPeriod]: {
    /**
     * Human-readable name
     */
    title: string;
    /**
     * Total period duration
     */
    duration: Duration | null;
    /**
     * How to align periods to compare them
     */
    alignment: DateTimeUnit;
    /**
     * Duration on a point in the period
     */
    pointGranularity: Duration;
    /**
     * Duration of a 'point' when aggregating totals over the period
     */
    totalGranularity: Duration;
  };
} = {
  [ChartPeriod.day]: {
    title: 'Day',
    duration: Duration.fromObject({ day: 1 }),
    alignment: 'day',
    pointGranularity: Duration.fromObject({ minutes: 15 }),
    totalGranularity: Duration.fromObject({ day: 1 })
  },
  [ChartPeriod.week]: {
    title: 'Week',
    duration: Duration.fromObject({ week: 1 }),
    alignment: 'week',
    pointGranularity: Duration.fromObject({ day: 1 }),
    totalGranularity: Duration.fromObject({ day: 1 })
  },
  [ChartPeriod.month]: {
    title: 'Month',
    duration: Duration.fromObject({ month: 1 }),
    alignment: 'month',
    pointGranularity: Duration.fromObject({ day: 1 }),
    totalGranularity: Duration.fromObject({ day: 1 })
  },
  [ChartPeriod.year]: {
    title: 'Year',
    duration: Duration.fromObject({ year: 1 }),
    alignment: 'year',
    pointGranularity: Duration.fromObject({ month: 1 }),
    totalGranularity: Duration.fromObject({ month: 1 })
  },
  [ChartPeriod.lifetime]: {
    title: 'Lifetime',
    duration: null,
    alignment: 'year',
    pointGranularity: Duration.fromObject({ year: 1 }),
    totalGranularity: Duration.fromObject({ year: 1 })
  }
};

/**
 * Returns aligned time interval which includes given base time: `[aligned base - period, aligned base)`.
 * E.g. if base is now and period is day, then observed interval is `[start of today, start of tomorrow)`.
 */
export const getObservedInterval = (base: DateTime<true>, period: ChartPeriod): Interval | null => {
  const { duration } = chartPeriodConfig[period];
  if (!duration) {
    return null;
  }

  const end = base.endOf(chartPeriodConfig[period].alignment).plus(1);

  return end.minus(duration).until(end);
};

/**
 * Set of predefined periods for widgets with system(s) performance.
 */
export enum ChartMilestone {
  yesterday = 'yesterday',
  week = 'week',
  month = 'month',
  year = 'year',
  lifetime = 'lifetime'
}

export const chartMilestoneNames: { [milestone in ChartMilestone]: string } = {
  [ChartMilestone.yesterday]: 'Yesterday',
  [ChartMilestone.week]: 'Last 7 days',
  [ChartMilestone.month]: 'Last 30 days',
  [ChartMilestone.year]: 'Last 12 months',
  [ChartMilestone.lifetime]: 'Lifetime'
};

export const chartMilestoneConfig: {
  [milestone in ChartMilestone]: {
    title: string;
    period: ChartPeriod;
    interval: () => Interval | null;
  };
} = {
  [ChartMilestone.yesterday]: {
    title: 'Yesterday',
    period: ChartPeriod.day,
    interval: () =>
      Interval.fromDateTimes(DateTime.now().minus({ day: 1 }).startOf('day'), DateTime.now().startOf('day'))
  },
  [ChartMilestone.week]: {
    title: 'Last 7 days',
    period: ChartPeriod.week,
    interval: () =>
      Interval.fromDateTimes(DateTime.now().minus({ day: 7 }).startOf('day'), DateTime.now().startOf('day'))
  },
  [ChartMilestone.month]: {
    title: 'Last 30 days',
    period: ChartPeriod.month,
    interval: () =>
      Interval.fromDateTimes(DateTime.now().minus({ day: 30 }).startOf('day'), DateTime.now().startOf('day'))
  },
  [ChartMilestone.year]: {
    title: 'Last 12 months',
    period: ChartPeriod.year,
    interval: () =>
      Interval.fromDateTimes(
        DateTime.now().minus({ months: 11 }).startOf('month'),
        DateTime.now().plus({ month: 1 }).startOf('month')
      )
  },
  [ChartMilestone.lifetime]: {
    title: 'Lifetime',
    period: ChartPeriod.lifetime,
    interval: () => null
  }
};
