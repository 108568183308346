import React from 'react';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { AccountIcon } from '@common/icons/Account';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { File, Monitor, Sunset, Tool } from 'react-feather';
import { FeedInbox } from '@generated/types/graphql';
import { useDrawersContext } from '@contexts/DrawersContext';
// some strange build error if import DrawerEntity from '@contexts/DrawersContext';
import { DrawerEntity } from '@contexts/DrawersContext/types';
import { EntityChip } from './styled';
import { NotficationEntity, NotificationEntityType } from './types';

const ICONS: Record<NotificationEntityType, React.ReactNode> = {
  [NotificationEntityType.Request]: <DollarIcon size="12px" />,
  [NotificationEntityType.Client]: <AccountIcon size={12} />,
  [NotificationEntityType.Project]: <BriefcaseIcon size="12px" />,
  [NotificationEntityType.File]: <File size="12px" />,
  [NotificationEntityType.System]: <Sunset size="12px" />,
  [NotificationEntityType.WorkOrder]: null
};

interface Props {
  entity: NotficationEntity | null;
  notification: FeedInbox;
}

const NOTIFICATION_ENTITY_TO_PATH: Record<NotificationEntityType, string> = {
  [NotificationEntityType.Request]: 'requests',
  [NotificationEntityType.Client]: 'clients',
  [NotificationEntityType.Project]: 'projects',
  [NotificationEntityType.File]: 'docs',
  [NotificationEntityType.System]: 'systems',
  [NotificationEntityType.WorkOrder]: 'workOrders'
};

const NOTIFICATION_ENTITY_TO_DRAWER_ENTITY: Record<NotificationEntityType, DrawerEntity | null> = {
  [NotificationEntityType.Request]: DrawerEntity.REQUEST,
  [NotificationEntityType.Client]: DrawerEntity.CLIENT,
  [NotificationEntityType.Project]: DrawerEntity.PROJECT,
  [NotificationEntityType.File]: DrawerEntity.FILE,
  [NotificationEntityType.System]: DrawerEntity.SYSTEM,
  [NotificationEntityType.WorkOrder]: DrawerEntity.WORK_ORDER,
  [NotificationEntityType.Reminder]: null,
  [NotificationEntityType.Action]: null,
  [NotificationEntityType.Geolocation]: null
};

export const NotificationEntityChip = ({ entity, notification }: Props) => {
  const { openDrawer } = useDrawersContext();

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    openDrawer(NOTIFICATION_ENTITY_TO_DRAWER_ENTITY[entity.type], entity.id, []);
  };

  switch (entity.type) {
    case NotificationEntityType.Project:
    case NotificationEntityType.Request:
    case NotificationEntityType.Client:
    case NotificationEntityType.File: {
      return (
        <EntityChip to={`./${NOTIFICATION_ENTITY_TO_PATH[entity.type]}/${entity.id}`} onClick={handleClick}>
          {ICONS[entity.type]}
          <div>
            #{entity.uid}
            {' · '}
            {entity.title}
          </div>
        </EntityChip>
      );
    }
    case NotificationEntityType.WorkOrder: {
      const { isField } = notification.feed.task;

      return (
        <EntityChip to={`./${NOTIFICATION_ENTITY_TO_PATH[entity.type]}/${entity.id}`} onClick={handleClick}>
          {isField ? <Tool size="12px" /> : <Monitor size="12px" />}
          <div>
            #{entity.uid}
            {' · '}
            {entity.title}
          </div>
        </EntityChip>
      );
    }

    case NotificationEntityType.System: {
      return (
        <EntityChip to={`./${NOTIFICATION_ENTITY_TO_PATH[entity.type]}/${entity.id}`} onClick={handleClick}>
          {ICONS[entity.type]}
          <div>{entity.title}</div>
        </EntityChip>
      );
    }

    default:
      return null;
  }
};
