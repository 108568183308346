import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CreateReminderDTO, UpdateReminderDTO } from '@types';
import projectApi from '@services/api/projectApi';
import { useToast } from '@hooks/useToast';
import { apiErrorHandler, trimWithDots } from '@utils';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { Reminder, ReminderFilter } from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';

export const useReminders = (filters: DeepPartial<ReminderFilter> = {}) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const filter: DeepPartial<ReminderFilter> = {
    companyId: { equalTo: companyId },
    ...filters
  };

  return useQuery<Reminder[]>([ReactQueryKey.RecordReminders, companyId, filter], async () => {
    try {
      return (
        await postGraphql<{ reminders: Reminder[] }>(
          gql`
            query REMINDER_LIST_QUERY($filter: ReminderFilter!) {
              reminders(filter: $filter, orderBy: [IS_COMPLETED_ASC, DUE_DATE_ASC]) {
                id
                title
                dueDate
                isCompleted
                companyId
                createdAt
                type
                description
                privilege
                isAppointment
                project {
                  id
                  type
                  companyId
                  title
                  status
                  requestStatus
                  accountStatus
                }
                createdByUser {
                  id
                  firstName
                  lastName
                  avatarUrl
                }
                assignee {
                  id
                  firstName
                  lastName
                  avatarUrl
                }
              }
            }
          `,
          { filter }
        )
      ).reminders;
    } catch (e) {
      throw apiErrorHandler('Error fetching reminders', e);
    }
  });
};

export const useRemindersMutations = (companyId?: number, isAppointment?: boolean) => {
  const { showSuccess, showError } = useToast();
  const queryClient = useQueryClient();

  const entityName = isAppointment ? 'Appointment' : 'Reminder';

  const createMutation = useMutation<Reminder, Error, { dto: CreateReminderDTO; recordId: number; companyId?: number }>(
    async ({ dto, recordId, companyId: $companyId }) => {
      try {
        return (
          await projectApi.createReminder(
            recordId,
            { ...dto, isAppointment: isAppointment ?? false },
            companyId || ($companyId as number)
          )
        ).data;
      } catch (e) {
        showError(`Error on ${entityName} creating`);

        throw apiErrorHandler(`Error creating ${entityName}`, e);
      }
    },
    {
      onSuccess: (data, { recordId }) => {
        queryClient.invalidateQueries(ReactQueryKey.RecordReminders);
        queryClient.invalidateQueries(ReactQueryKey.ListViewReminders);

        if (isAppointment) {
          queryClient.invalidateQueries([ReactQueryKey.RecordDetail, recordId]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]);
        }
        showSuccess(`${entityName} <b>${trimWithDots(data.title as string, 100)}</b> successfully created`);
      }
    }
  );

  const updateMutation = useMutation<Reminder, Error, { id: number; dto: UpdateReminderDTO; companyId?: number }>(
    async ({ id, dto, companyId: $companyId }) => {
      try {
        return (await projectApi.updateReminder(id, dto, companyId || ($companyId as number))).data;
      } catch (e) {
        showError(`Error on ${entityName} updating`);

        throw apiErrorHandler(`Error updating ${entityName}`, e);
      }
    },
    {
      onSuccess: ({ title, project, isAppointment }) => {
        queryClient.invalidateQueries(ReactQueryKey.RecordReminders);
        queryClient.invalidateQueries(ReactQueryKey.ListViewReminders);

        if (isAppointment) {
          queryClient.invalidateQueries([ReactQueryKey.RecordDetail, project?.id]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]);
        }
        showSuccess(
          `${isAppointment ? 'Appointment' : 'Reminder'} <b>${trimWithDots(title as string, 100)}</b> successfully updated`
        );
      }
    }
  );

  const deleteMutation = useMutation<void, Error, { id: number; recordId: number }>(
    async ({ id }) => {
      try {
        await projectApi.deleteReminder(id, companyId);
      } catch (e) {
        throw apiErrorHandler(`Error deleting ${entityName}`, e);
      }
    },
    {
      onSuccess: (_, { recordId }) => {
        queryClient.invalidateQueries(ReactQueryKey.RecordReminders);
        queryClient.invalidateQueries(ReactQueryKey.ListViewReminders);

        if (isAppointment && recordId) {
          queryClient.invalidateQueries([ReactQueryKey.RecordDetail, recordId]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]);
        }

        showSuccess(`${entityName} successfully deleted`);
      }
    }
  );

  return {
    createMutation,
    updateMutation,
    deleteMutation
  };
};
