import { Team } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/index';
import { useTeams } from '@hooks/useTeams';
import { selectWorkspaceId } from '@state/selectors';
import { useMemo } from 'react';

export const useTeamsMap = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { data: teams, isLoading } = useTeams(companyId);
  const teamsMap: Record<number, Team> = useMemo(() => {
    return (teams || []).reduce<Record<number, Team>>((acc, team) => {
      return {
        ...acc,
        [team.id]: team
      };
    }, {});
  }, [teams]);

  return {
    teamsMap,
    isLoading
  };
};
