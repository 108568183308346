import { useQuery } from 'react-query';

import { ReactQueryKey } from '@enums';
import projectApi from '@services/api/projectApi';
import { apiErrorHandler } from '@utils';
import { MemberFromProjectListApi } from '@types';

const fetchMembers = async ({ queryKey }: { queryKey: [any, number] }) => {
  try {
    const [, projectId] = queryKey;

    if (!projectId) {
      return null;
    }

    const { data } = await projectApi.getProjectMember(projectId);

    return data;
  } catch (e) {
    return apiErrorHandler('Error on fetch project members', e);
  }
};

export const useMembers = (projectId?: number) => {
  const QUERY_KEY = [ReactQueryKey.ProjectMembers, projectId];

  const { data: list, isLoading } = useQuery<MemberFromProjectListApi[]>(QUERY_KEY, fetchMembers, {
    initialData: [],
    staleTime: 1000 * 3600 * 24,
    initialDataUpdatedAt: Date.now() - 1000 * 3600 * 24,
    enabled: !!projectId
  });

  return { list, isLoading };
};
