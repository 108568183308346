import React, { useCallback } from 'react';
import { Container, SoonLabel, Tab } from './styled';

export interface TabItem<IdType = string | number> {
  id: IdType;
  title: string;
  isSoon?: boolean;
  isDisabled?: boolean;
}

interface Props<IdType extends string | number, T extends TabItem<IdType>> {
  variant?: 'default' | 'outline';
  isFullWidth?: boolean;
  selected: IdType;
  tabs: T[];
  onChange: (tab: T) => void;
}

export const Tabs = <IdType extends string | number, T extends TabItem<IdType>>({
  tabs,
  selected,
  onChange,
  variant = 'default',
  isFullWidth = false
}: Props<IdType, T>) => {
  const handleClick = useCallback(
    (tab: T) => () => {
      onChange(tab);
    },
    [onChange]
  );

  return (
    <Container isFullWidth={isFullWidth} variant={variant}>
      {tabs.map((tab) => (
        <Tab
          isDisabled={tab.isDisabled}
          variant={variant}
          isActive={tab.id === selected}
          key={tab.id}
          onClick={tab.isDisabled ? undefined : handleClick(tab)}
        >
          {tab.title}

          {tab.isSoon === true && <SoonLabel> (Soon!)</SoonLabel>}
        </Tab>
      ))}
    </Container>
  );
};
