import { ModalBody, ModalFooter, useConfirmModal } from '@common/PromiseModal';
import { PropertyValueField } from '@features/Platform/Properties/PropertyValueField';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Property, RecordType } from '@types';
import { isFiles, isPerson } from '@utils/properties';
import React, { useMemo } from 'react';
import { useProjectBulk } from '@hooks/useProjectBulk';
import { useWorkflowList } from '@hooks/workflows/useWorkflowList';
import { useStageList } from '@hooks/workspace/stages/useStageList';
import { RECORD_TYPE_TITLE_MAP } from '@features/Analytics/constants';

interface Props {
  onClose: () => void;
  recordType: RecordType;
  recordIds: number[];
}

enum EditType {
  UpdateProperty = 'updateProperty',
  UpdateWorkflow = 'updateWorkflow',
  UpdateStage = 'updateStage'
}

type FormValues = {
  editType: { label: string; value: EditType };
  property?: Property;
  propertyValue?: any;
  workflow?: { id: number; name: string };
  workflowStage?: { id: number; name: string };
  stage?: { id: number; name: string };
};

const RULES: FormValidationRules<FormValues> = {
  editType: {
    isRequired: true
  },
  property: {
    isRequired: true
  },
  propertyValue: {
    isRequired: true
  },
  workflow: {
    isRequired: true
  },
  workflowStage: {
    isRequired: true
  },
  stage: {
    isRequired: true
  }
};

export const Edit = ({ recordIds, recordType, onClose }: Props) => {
  const { scopeToEditableColumns } = useCompanyProperties();

  const { data: workflows = [] } = useWorkflowList();
  const { data: stages = [] } = useStageList();
  const confirm = useConfirmModal();

  const workflowOptions = useMemo(
    () => workflows.filter((workflow) => workflow.type === recordType),
    [workflows, recordType]
  );

  const {
    update: { mutateAsync: bulkUpdate }
  } = useProjectBulk(recordType);

  const editableProperties = useMemo(
    () => (scopeToEditableColumns[recordType] ?? []).filter((property) => !isFiles(property)),
    [scopeToEditableColumns, recordType]
  );

  const editTypeOptions = useMemo(() => {
    if (recordType === RecordType.ACCOUNT) {
      return [{ label: 'Update Property', value: EditType.UpdateProperty }];
    }

    return [
      { label: 'Update Property', value: EditType.UpdateProperty },
      { label: 'Update Workflow', value: EditType.UpdateWorkflow },
      { label: 'Update Stage', value: EditType.UpdateStage }
    ];
  }, [recordType]);

  const { form, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      editType: editTypeOptions[0]
    },
    onSubmit: async ({ editType, property, propertyValue, workflow, workflowStage, stage }) => {
      const type = editType?.value;

      if (type === EditType.UpdateProperty) {
        let value = propertyValue?.id ?? propertyValue ?? null;

        if (propertyValue && property.additional && isPerson(property)) {
          value = { id: propertyValue?.id };
        }

        await bulkUpdate({
          projects: recordIds,
          field: {
            fieldId: property.id,
            value
          }
        });
      }

      if (type === EditType.UpdateWorkflow) {
        if (
          !(await confirm({
            confirmText: `Please note that updating the Workflow will keep existing Work Orders in the same ${RECORD_TYPE_TITLE_MAP[recordType]} Stages, as long as they exist in the new Workflow. Otherwise they will appear in the first ${RECORD_TYPE_TITLE_MAP[recordType]} Stage of the new Workflow. Existing Docs will remain in the same ${RECORD_TYPE_TITLE_MAP[recordType]} Stage Folders, as long as they exist as well. Otherwise they will be moved to the "Other" Folder. This action can not be undone.`
          }))
        ) {
          return;
        }
        await bulkUpdate({
          projects: recordIds,
          field: {
            fieldId: -24,
            value: workflow?.id,
            stageId: workflowStage?.id
          }
        });
      }

      if (type === EditType.UpdateStage) {
        if (
          !(await confirm({
            confirmText: `Please note that if a ${RECORD_TYPE_TITLE_MAP[recordType]} doesn’t have "${stage?.name}" Stage in its Workflow, 
                this action will interrupt the Workflow. This action can not be undone.`
          }))
        ) {
          return;
        }
        await bulkUpdate({
          projects: recordIds,
          field: {
            fieldId: -25,
            value: stage?.id
          }
        });
      }

      onClose();
    }
  });

  const {
    control,
    formState: { isSubmitting },
    watch
  } = form;

  const editType = watch('editType')?.value;
  const selectedProperty = watch('property');
  const workflow = watch('workflow');

  const workflowStageOptions = useMemo(() => {
    if (!workflow) {
      return [];
    }

    return stages
      .filter((stage) => stage.scope === recordType)
      .map((stage) => {
        if (workflow?.blueprintProjectStages.some(({ projectStage }) => projectStage.id === stage.id)) {
          return {
            id: stage.id,
            name: stage.name,
            isInWorkflow: true
          };
        }

        return stage;
      })
      .sort((a, b) => {
        if (a.isInWorkflow && !b.isInWorkflow) {
          return -1;
        }

        if (!a.isInWorkflow && b.isInWorkflow) {
          return 1;
        }

        return 0;
      });
  }, [workflow, stages, recordType]);

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <ModalBody>
        <SelectField
          control={control}
          label="Bulk edit type"
          name="editType"
          options={editTypeOptions}
          getOptionLabel={(option) => option.label}
          isClearable={false}
        />

        {editType === EditType.UpdateProperty && (
          <>
            <SelectField
              control={control}
              label="Property"
              name="property"
              options={editableProperties}
              getOptionLabel={(option) => option.name}
            />

            {selectedProperty && (
              <PropertyValueField
                control={control}
                property={selectedProperty}
                name="propertyValue"
                label="New value"
              />
            )}
          </>
        )}

        {editType === EditType.UpdateWorkflow && (
          <>
            <SelectField
              control={control}
              label="New Workflow"
              name="workflow"
              options={workflowOptions}
              getOptionLabel={(option) => option.name}
            />

            <SelectField
              control={control}
              label="New Stage"
              name="workflowStage"
              options={workflowStageOptions}
              getOptionLabel={(option) => option.name}
              groupBy={(option) => (option.isInWorkflow ? 'In Workflow' : 'Not in Workflow')}
            />
          </>
        )}

        {editType === EditType.UpdateStage && (
          <SelectField
            control={control}
            label="New Stage"
            name="stage"
            options={stages.filter((stage) => stage.scope === recordType)}
            getOptionLabel={(option) => option.name}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
          Apply
        </Button>
      </ModalFooter>
    </Form>
  );
};
