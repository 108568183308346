import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit2, FileText, Plus, X } from 'react-feather';
import { Financial } from '@components/Project/Financial';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess, hasEntityAccessNew } from '@utils/roles';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { InfoIcon } from '@kit/ui/icons/Info';
import { ContextMenu } from '@kit/components/ContextMenu';
import { useRecordDetail } from '@hooks/useRecordDetail';

import { RecordType } from '@types';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { PlainDocList, useCreateDocMenuItems } from '@features/DocumentManagement/DocList';
import { stopPropagationClick } from '@utils';
import {
  Container,
  CreateWork,
  Details,
  DetailsContent,
  DetailsHeader,
  DetailsTitle,
  EditIconButton,
  EditProperties,
  IconButton,
  Widget,
  WidgetDetailsButton,
  WidgetHeader,
  WidgetIcon,
  WidgetTitle
} from './styled';
import { Docs as DocsWidget } from './Docs';
import { Stakeholders } from './Stakeholders';
import { Properties as PropertiesWidget } from './Properties';
import { FullProperties } from './Properties/FullProperties';
import { Reminders } from './Reminders';
import { OverviewSkeleton } from './Skeleton';
import { Sites } from './Sites';
import { Accounting } from './Accounting';
import { LifetimeEnergy } from './LifetimeEnergy';

interface Props {
  projectId: number;
}

type TDetails = 'financials' | 'docs' | 'properties' | 'work';

export const Overview = ({ projectId }: Props) => {
  const { data: record, isLoading } = useRecordDetail(projectId, { refetchOnMount: false });
  const canEdit = record && hasEntityAccessNew(record, 'edit');
  const [expandedDetails, setExpandedDetails] = useState<TDetails | null>(null);
  const companyId = useAppSelector(selectWorkspaceId);
  const userId = useAppSelector(selectCurrentUserId);
  const { data: access, isFetching } = useUserRoleSettings(companyId, userId);
  // const { setParams } = useQueryParamMutation();
  const canViewFinances = hasAccess(access, 'contract', 'view', 'contract');
  const canViewWork = hasAccess(access, 'task', 'view');
  const canViewDocs = hasAccess(access, 'file', 'view') || hasAccess(access, 'form', 'view');
  const canCreateForm = hasAccess(access, 'form', 'create');
  const canCreateFile = hasAccess(access, 'file', 'create');

  const createFileMenuItems = useCreateDocMenuItems(projectId);

  const expandDetails = useCallback(
    (id: TDetails) => () => {
      if (id === 'properties' && (isLoading || record?.id !== projectId)) {
        return;
      }
      setExpandedDetails(id);
    },
    [isLoading, record, projectId]
  );

  useEffect(() => {
    setExpandedDetails(null);
  }, [projectId]);

  const details = useMemo(() => {
    if (!expandedDetails) {
      return null;
    }

    switch (expandedDetails) {
      case 'financials':
        return {
          title: 'Financials',
          content: <Financial recordId={projectId} />
        };
      case 'docs':
        return {
          title: 'Documents',
          content: <PlainDocList recordId={projectId} recordType={RecordType.ACCOUNT} />
        };

      case 'properties':
        return {
          title: 'Properties',
          content: <FullProperties projectId={projectId} />
        };
      default:
        return null;
    }
  }, [expandedDetails, projectId]);

  if (expandedDetails && details) {
    if (expandedDetails === 'properties') {
      return (
        <EditProperties
          projectId={projectId}
          recordType={RecordType.ACCOUNT}
          onClose={() => setExpandedDetails(null)}
        />
      );
    }

    return (
      <Details>
        <DetailsHeader>
          <DetailsTitle>{details.title}</DetailsTitle>
          <IconButton onClick={() => setExpandedDetails(null)}>
            <X size="28px" />
          </IconButton>
        </DetailsHeader>
        <DetailsContent>{details.content}</DetailsContent>
      </Details>
    );
  }

  if (isFetching || !record?.id) {
    return <OverviewSkeleton />;
  }

  return (
    <Container>
      <LifetimeEnergy />

      <Sites clientId={projectId} />

      <Widget size="large" isFullWidth>
        <WidgetHeader>
          <WidgetTitle onClick={expandDetails('properties')}>
            <WidgetIcon backgroundColor="#DFDFE8">
              <InfoIcon size="16px" color="#737382" />
            </WidgetIcon>
            Properties
          </WidgetTitle>
          {canEdit && (
            <EditIconButton onClick={expandDetails('properties')}>
              <Edit2 size="16px" />
            </EditIconButton>
          )}

          {!canEdit && (
            <WidgetDetailsButton onClick={expandDetails('properties')}>
              <ChevronRightIcon size={24} />
            </WidgetDetailsButton>
          )}
        </WidgetHeader>

        <PropertiesWidget projectId={projectId} />
      </Widget>

      {canViewWork && <Reminders projectId={projectId} />}

      <Stakeholders isFullWidth projectId={projectId} />

      {canViewDocs && (
        <Widget isFullWidth>
          <WidgetHeader>
            <WidgetTitle onClick={expandDetails('docs')}>
              <WidgetIcon backgroundColor="#DBE5FF">
                <FileText size="16px" color="#235DFF" />
              </WidgetIcon>
              Docs
              {(canCreateFile || canCreateForm) && (
                <CreateWork {...stopPropagationClick}>
                  <ContextMenu position="left" items={createFileMenuItems}>
                    <Button variant={ButtonVariant.Secondary}>
                      <Plus size="16px" />
                      Doc
                    </Button>
                  </ContextMenu>
                </CreateWork>
              )}
            </WidgetTitle>
            <WidgetDetailsButton onClick={expandDetails('docs')}>
              <ChevronRightIcon size={24} />
            </WidgetDetailsButton>
          </WidgetHeader>

          <DocsWidget projectId={projectId} />
        </Widget>
      )}
      {canViewFinances && <Accounting recordId={projectId} />}
    </Container>
  );
};
