import { ModalBody, ModalFooter, useConfirmDeleteModal } from '@common/PromiseModal';
import { DashboardListItem } from '@hooks/analytics/useDashboardList';
import { useCreateSmartViewFolder } from '@hooks/smartViews/useCreateSmartViewFolder';
import { useSmartViewFolderList } from '@hooks/smartViews/useSmartViewFolderList';
import { useAppSelector } from '@hooks/store';
import { useAuth } from '@hooks/useAuth';
import { useUserRoleSettings } from '@hooks/useRoles';
import {
  Form,
  FormValidationRules,
  InputField,
  RadioGroupField,
  RichTextField,
  SelectField,
  useForm
} from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { selectWorkspaceId } from '@state/selectors';
import { hasAccess } from '@utils/roles';
import React, { useCallback, useRef } from 'react';
import { useCreateDashboard } from '@hooks/analytics/useCreateDashboard';
import { useUpdateDashboard } from '@hooks/analytics/useUpdateDashboard';
import { useDeleteDashboard } from '@hooks/analytics/useDeleteDashboard';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { SMART_VIEW_EMOJIES } from '@constants/emoji';
import { ViewFilterScope } from '@types';
import { AccessList, TAccessList, AccessEntityType } from '@features/Platform/AccessList';
import { Role } from '@generated/types/graphql';
import { EmojiAndName, EmojiList } from './styled';

const EMOJIES_OPTIONS = SMART_VIEW_EMOJIES.map((emoji) => ({ id: emoji, name: emoji }));

const ACCESS_OPTIONS = [
  {
    value: ViewFilterScope.PERSONAL,
    label: 'Keep private'
  },
  {
    value: ViewFilterScope.WORKSPACE,
    label: 'Share with the entire Workspace'
  },
  {
    value: ViewFilterScope.SHARED,
    label: 'Share with specific Users, Teams and/or Roles'
  }
];

const checkRoleHasAnalyticsAccess = (role: Pick<Role, 'settings'>) => {
  return (role.settings.workspace?.analytics ?? 'none') !== 'none';
};

type FormValues = {
  emoji: { id: string; name: string };
  name: string;
  folder: { id: number; name: string } | null;
  description: string;
  scope: ViewFilterScope;
};

interface Props {
  initialValues?: DashboardListItem;

  onClose: (createdId?: number) => void;
}

const RULES: FormValidationRules<FormValues> = {
  name: {
    isRequired: true
  },
  scope: {
    isRequired: true
  }
};

export const DashboardSettings = ({ initialValues, onClose }: Props) => {
  const isNew = !initialValues?.id;

  const accessListRef = useRef<TAccessList>([]);

  const { mutateAsync: create } = useCreateDashboard();
  const { mutateAsync: update } = useUpdateDashboard();
  const { mutateAsync: deleteDashboard } = useDeleteDashboard();
  const confirmDelete = useConfirmDeleteModal();

  const [, setCurrentDashboardId] = useQueryParam(QueryParamsEnum.DashboardId);

  const { user } = useAuth();
  const companyId = useAppSelector(selectWorkspaceId);

  const { data: roleAccess } = useUserRoleSettings(companyId, user.userId);

  const { mutateAsync: createFolder } = useCreateSmartViewFolder();

  const { data: folders } = useSmartViewFolderList();

  const postForm = async (values: FormValues) => {
    let filterFolderId = values.folder?.id ?? null;

    const sharedSettings =
      values.scope === ViewFilterScope.SHARED
        ? accessListRef.current.reduce(
            (acc, item) => {
              switch (item.type) {
                case AccessEntityType.USER:
                  acc.sharedUsers.push(item.value.id);

                  break;

                case AccessEntityType.TEAM:
                  acc.sharedTeams.push(item.value.id);

                  break;
                case AccessEntityType.ROLE:
                  acc.sharedRoles.push(item.value.id);

                  break;

                default:
                  break;
              }

              return acc;
            },
            {
              sharedRoles: [] as number[],
              sharedTeams: [] as number[],
              sharedUsers: [] as number[]
            }
          )
        : {
            sharedRoles: [],
            sharedTeams: [],
            sharedUsers: []
          };

    if (values.folder?.id === 0) {
      filterFolderId = await createFolder({ name: values.folder.name });
    }

    if (isNew) {
      const createdId = await create({
        name: values.name,
        emoji: values.emoji.id,
        description: values.description,
        filterFolderId,
        layout: initialValues?.layout ?? [],
        scope: values.scope,
        ...sharedSettings
      });

      onClose(createdId);
    } else {
      await update({
        id: initialValues!.id,
        dto: {
          name: values.name,
          emoji: values.emoji.id,
          description: values.description,
          filterFolderId,
          scope: values.scope,
          ...sharedSettings
        }
      });

      onClose();
    }
  };

  const handleDelete = useCallback(async () => {
    if (await confirmDelete('Are you sure you want to delete this dashboard?')) {
      await deleteDashboard(initialValues.id);

      setCurrentDashboardId(undefined);
      onClose();
    }
  }, [confirmDelete, deleteDashboard, initialValues, onClose, setCurrentDashboardId]);

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      emoji: initialValues?.emoji ? { id: initialValues.emoji, name: initialValues.emoji } : { id: '📊', name: '📊' },
      name: initialValues?.name || '',
      description: initialValues?.description || '',
      folder: initialValues?.filterFolder || null,
      scope: (initialValues?.scope as ViewFilterScope) || ViewFilterScope.PERSONAL
    }
  });

  const { control, watch } = form;

  const scope = watch('scope');

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <ModalBody width="750px">
        <EmojiAndName>
          <SelectField
            label="Emoji"
            name="emoji"
            options={EMOJIES_OPTIONS}
            getOptionLabel={(option) => option.name}
            control={control}
            isClearable={false}
            PaperComponent={EmojiList}
          />
          <InputField label="Name" name="name" control={control} />
        </EmojiAndName>

        <SelectField
          label="Hub"
          name="folder"
          getOptionLabel={(option) => option.name}
          options={folders ?? []}
          isCreatable={hasAccess(roleAccess!, 'workspace', 'create', 'smartviewFolder')}
          placeholder={
            hasAccess(roleAccess!, 'workspace', 'create', 'smartviewFolder')
              ? 'Select Hub or create new one, by default will be added to Other Hub'
              : 'Select Hub, by default will be added to Other Hub'
          }
          createButtonText={(inputValue: string) =>
            inputValue ? `+ Create "${inputValue}" as new Hub` : '+ Create new Hub'
          }
          buildNewOption={(inputValue: string) => {
            return { id: 0, name: inputValue };
          }}
          control={control}
        />
        <RichTextField label="Description" name="description" control={control} />

        <RadioGroupField label="Access" name="scope" options={ACCESS_OPTIONS} control={control} />
        {scope === ViewFilterScope.SHARED && (
          <AccessList
            accessEntityName="Analytics"
            checkAccessFn={checkRoleHasAnalyticsAccess}
            ref={accessListRef}
            initialValue={initialValues}
          />
        )}
      </ModalBody>
      <ModalFooter>
        {!isNew && (
          <Button variant={ButtonVariant.Danger} onClick={handleDelete}>
            Delete
          </Button>
        )}
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={ButtonVariant.Primary} type="submit">
          {isNew ? 'Create' : 'Update'}
        </Button>
      </ModalFooter>
    </Form>
  );
};
