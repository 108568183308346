import { useNotificationList } from '@hooks/notifications/useNotificationList';
import { useCallback, useMemo, useRef } from 'react';
import { CreatedSource, EventType, FeedInboxFilter, FeedInboxesOrderBy } from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import {
  ClientFilterState,
  NotificationStatus,
  NotificationsTypeFilter,
  useClientFilterState
} from '../useClientFilterState';

const NOTIFICATION_TYPE_TO_EVENT_TYPES: Record<NotificationsTypeFilter, EventType[]> = {
  [NotificationsTypeFilter.All]: [],
  [NotificationsTypeFilter.Portal]: [
    EventType.PortalMessagesReceived,
    EventType.ReferralSubmitted,
    EventType.RequestCreatedInPortal,
    EventType.ActionSentToReview,
    EventType.PortalDocumentUploaded
  ],
  [NotificationsTypeFilter.Email]: [EventType.EmailReceived],
  [NotificationsTypeFilter.SMS]: [EventType.SmsReceived],
  [NotificationsTypeFilter.Mentions]: [EventType.MentionsAnywhere],
  [NotificationsTypeFilter.Reminders]: [
    EventType.ReminderOverdue,
    EventType.ReminderAssignedOrReassigned,
    EventType.ReminderRescheduled
  ],
  [NotificationsTypeFilter.Requests]: [
    EventType.SalesRepUpdated,
    EventType.RequestCreated,
    EventType.RequestOwnerUpdated,
    EventType.RequestPmUpdated,
    EventType.RequestAppointmentScheduled,
    EventType.FutureRequestAppointmentScheduled
  ],
  [NotificationsTypeFilter.Projects]: [
    EventType.SalesRepAssignedOrUpdated,
    EventType.ProjectCreated,
    EventType.ProjectManagerUpdated,
    EventType.ProjectOwnerUpdated
  ],
  [NotificationsTypeFilter.Clients]: [EventType.ClientCreated, EventType.ClientOwnerUpdated],
  [NotificationsTypeFilter.WorkOrders]: [
    EventType.TodaysVisitScheduled,
    EventType.FutureVisitScheduled,
    EventType.AssigneeAddedOrUpdated,
    EventType.CollaboratorsAddedOrUpdated
  ]
};

const mapClientFiltersToGraphqlFilter = (clientFilters: ClientFilterState): DeepPartial<FeedInboxFilter> => {
  const result: DeepPartial<FeedInboxFilter> = {};

  if (clientFilters.types.length > 0) {
    const isAll = clientFilters.types.length === 1 && clientFilters.types[0] === NotificationsTypeFilter.All;

    if (!isAll) {
      if (clientFilters.types.includes(NotificationsTypeFilter.Portal)) {
        result.or = [
          {
            eventType: {
              in: NOTIFICATION_TYPE_TO_EVENT_TYPES[NotificationsTypeFilter.Portal]
            }
          },
          {
            eventType: {
              equalTo: EventType.ClientCreated
            },
            feed: {
              project: {
                source: {
                  notEqualTo: CreatedSource.Internal
                }
              }
            }
          }
        ];
      } else {
        result.eventType = {
          in: clientFilters.types.flatMap((type) => NOTIFICATION_TYPE_TO_EVENT_TYPES[type])
        };
      }
    }
  }

  if (clientFilters.statuses.length === 1 && clientFilters.statuses.includes(NotificationStatus.Unread)) {
    result.read = { equalTo: false };
  }

  if (clientFilters.query) {
    result.feed = {
      searchText: {
        includes: clientFilters.query.toLowerCase()
      }
    };
  }

  return result;
};

export const useNotifications = () => {
  const loadingRef = useRef<boolean>(false);

  const { clientFilters } = useClientFilterState();

  const { data, fetchNextPage, isLoading, isFetching, isFetchingNextPage, refetch } = useNotificationList({
    orderBy: clientFilters.sortByCreatedAtAsc ? FeedInboxesOrderBy.CreatedAtAsc : FeedInboxesOrderBy.CreatedAtDesc,
    filters: mapClientFiltersToGraphqlFilter(clientFilters)
  });

  const handleFetchNextPage = useCallback(() => {
    if (!loadingRef.current) {
      loadingRef.current = true;
      fetchNextPage().finally(() => {
        loadingRef.current = false;
      });
    }
  }, [fetchNextPage]);

  const flatten = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.flatMap((page) => page.notifications);
  }, [data]);

  return {
    notifications: flatten,
    total: data?.pages[0]?.totalCount ?? 0,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage: handleFetchNextPage,
    refetch
  };
};
