import { useCreateActionItem } from '@hooks/actionItems/useCreateActionItem';
import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import React, { useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useActionTemplateList } from '@hooks/templates/actions/useActionTemplateList';
import { useWorkflowList, WorkflowListItem } from '@hooks/workflows/useWorkflowList';
import { Fields, FooterActions } from './styled';

interface FormValues {
  template: { id: number; title: string };
  stage: { id: number; name: string };
}

interface Props {
  initialRecord: { id: number; title: string; blueprintId: number; stage: { id: number; name: string } };
  onClose: (newId: number | void) => void;
}

export const CreateActionItemForm = ({ initialRecord, onClose }: Props) => {
  const { mutateAsync: create } = useCreateActionItem();

  const { data: templates = [] } = useActionTemplateList();

  const { data: workflows = [] } = useWorkflowList();

  const workflowsById = useMemo(
    () =>
      workflows.reduce(
        (acc, workflow) => ({ ...acc, [workflow.id]: workflow }),
        {} as { [key: number]: WorkflowListItem }
      ),
    [workflows]
  );

  const projectStages = useMemo(() => {
    if (!initialRecord || !workflowsById[initialRecord.blueprintId]) {
      return [];
    }

    return workflowsById[initialRecord.blueprintId].blueprintProjectStages.map(({ projectStage }) => projectStage);
  }, [initialRecord, workflowsById]);

  const postForm = async (values: FormValues) => {
    const newId = await create({
      projectId: initialRecord.id,
      actionTemplateId: values.template.id,
      projectStageId: values.stage.id
    });

    onClose(newId);
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      stage: initialRecord.stage,
      template: null
    }
  });

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      stage: {
        isRequired: true
      },
      template: {
        isRequired: true
      }
    }),
    []
  );

  const {
    formState: { isSubmitting, isDirty },
    control
  } = form;

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Fields>
        <SelectField
          options={templates}
          getOptionLabel={(option) => option.title}
          label="Action Item Template"
          control={control}
          name="template"
        />
        <SelectField
          options={projectStages}
          label="Add to Stage"
          getOptionLabel={(option) => option.name}
          control={control}
          name="stage"
          isClearable={false}
        />
      </Fields>
      <FooterActions>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting || !isDirty} type="submit" variant={ButtonVariant.Primary}>
          Create
        </Button>
      </FooterActions>
    </Form>
  );
};
