import { UserRole } from '@enums';
import { Privilege, RoleDTO, RoleSettingsDTO, Scope, Subject, WithPrivileges } from '@types';
import { maxBy } from 'lodash';

export const DEFAULT_ROLES_NAMES: { [name: string]: string } = {
  [UserRole.ADMIN]: 'Admin',
  [UserRole.PROJECT_MANAGER]: 'Manager',
  [UserRole.WORKER]: 'Worker',
  [UserRole.SALES]: 'Sales'
};

export const EMPTY_ROLE_SETTINGS: RoleSettingsDTO = {
  project: { own: 'none', team: 'none', all: 'none' },
  task: { own: 'none', team: 'none', all: 'none' },
  form: { own: 'none', team: 'none', all: 'none' },
  file: { own: 'none', team: 'none', all: 'none' },
  team: { own: 'none', all: 'none' },
  workspace: {
    settings: 'none',
    analytics: 'none',
    invite: 'none',
    import: 'none',
    smartview: 'view',
    smartviewFolder: 'view'
  },
  contract: {
    contract: 'none'
  }
};

/**
 * Ordered from the least powerful to the most
 */
export const PRIVILEGES: Privilege[] = ['none', 'view', 'edit', 'create', 'delete'];

export const isPrivilegeGranted = (actual?: Privilege, target?: Privilege) =>
  PRIVILEGES.indexOf(actual || 'none') >= PRIVILEGES.indexOf(target || 'none');

export const hasAccess = (settings: RoleSettingsDTO, subject: Subject, privilege: Privilege, scope?: Scope) =>
  scope
    ? isPrivilegeGranted(settings![subject]?.[scope], privilege)
    : Object.values(settings![subject] || {}).some((scopePrivilege) => isPrivilegeGranted(scopePrivilege, privilege));

const normalizePrivileges = (entity: WithPrivileges): Privilege[] => {
  if ('privileges' in entity) {
    return entity.privileges || [];
  }

  const max =
    maxBy([entity.privilegeOwn, entity.privilegeTeam, entity.privilegeAll], (privilege) =>
      PRIVILEGES.indexOf(privilege)
    ) || 'none';

  return PRIVILEGES.slice(0, PRIVILEGES.indexOf(max) + 1);
};

export const hasEntityAccess = (entity: WithPrivileges, privilege: Privilege) =>
  normalizePrivileges(entity).includes(privilege);

export const hasEntityAccessNew = (entity: { privilege: string | Privilege }, privilege: Privilege) => {
  return PRIVILEGES.slice(0, PRIVILEGES.indexOf(entity.privilege as Privilege) + 1).includes(privilege);
};

export const normalizeRoleName = (name: string) => DEFAULT_ROLES_NAMES[name] || name;

export const isFixedRole = (role: RoleDTO) =>
  [UserRole.ADMIN, UserRole.PROJECT_MANAGER, UserRole.WORKER].some((roleType) => roleType === role.name);
