import React from 'react';
import { Field } from 'formik';
import { InputLabel } from '@common/ui';
import { UserSelector } from '@common/Selector/UserSelector';
import { User } from '@types';

type AssigneesInputType = {
  label?: string;
  name?: string;
  projectID?: number;
  companyId?: number;
  membersOnly?: boolean;
  allowTeams?: boolean;
  allowNull?: boolean;
  allowUserProperties?: boolean;
  isMultiple?: boolean;
  excludeIds?: number[];
  setTeamCollaborators?: (teamUsers: User[]) => void;
};

const AssigneesInput: React.FC<AssigneesInputType> = ({
  label,
  name,
  projectID,
  membersOnly = true,
  allowTeams = false,
  companyId,
  allowNull = false,
  allowUserProperties = false,
  isMultiple = true,
  excludeIds = [],
  onFocus,
  onBlur,
  setTeamCollaborators
}) => {
  const getSeletedUsers = (values) => {
    if (!isMultiple) {
      if (!values[name]) {
        return [];
      }

      return [values[name]];
    }

    if (allowNull) {
      return values[name] ?? [];
    }

    return values[name];
  };

  const setSelectedUsers = (users, setFieldValue) => {
    if (isMultiple) {
      setFieldValue(name, users);
    } else {
      const userOrTeam = users[0];

      if (userOrTeam?.type === 'team') {
        setFieldValue(name, userOrTeam.teamLead ?? null);

        const teamCollaborators = (userOrTeam.teamUsers ?? []).filter((user: User) => {
          if (userOrTeam.teamLead) {
            return user.id !== userOrTeam.teamLead.id;
          }

          return true;
        });

        setTeamCollaborators?.(teamCollaborators);
      } else {
        setFieldValue(name, users[0] ?? null);
      }
    }
  };

  return (
    <div data-testid="assignees">
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Field name={name}>
        {({ form: { setFieldValue, values } }) => (
          <UserSelector
            selectedUsers={getSeletedUsers(values)}
            setSelectedUsers={(users) => setSelectedUsers(users, setFieldValue)}
            projectId={projectID}
            membersOnly={membersOnly}
            allowTeams={allowTeams}
            companyId={companyId}
            allowUserProperties={allowUserProperties}
            onFocus={onFocus}
            onBlur={onBlur}
            isMultiple={isMultiple}
            excludeIds={excludeIds}
          />
        )}
      </Field>
    </div>
  );
};

export default AssigneesInput;
