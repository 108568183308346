import React from 'react';
import { User as UserIcon, Users as TeamIcon } from 'react-feather';
import { defaultTheme } from '@themes';
import { Avatar, IconWrapper, OptionItemWrapper, ValueStrongPart } from './styled';

interface IProps {
  name: string;
  avatarUrl?: string;
  onClick?: () => void;
  hideAvatar?: boolean;
  isTeam?: boolean;
  usersLength?: number;
}

const getTokenDetails = (name: string) => {
  const [label, value] = name.split(': ');

  return {
    label: label?.trim() || '',
    value: value?.trim() || ''
  };
};

const getThumbnail = (name: string, avatarUrl?: string, isTeam?: boolean) => {
  if (avatarUrl) {
    return <Avatar src={avatarUrl} alt={name} />;
  }

  if (isTeam) {
    return (
      <IconWrapper>
        <TeamIcon size={16} color={defaultTheme.colors.gray} />
      </IconWrapper>
    );
  }

  return (
    <IconWrapper>
      <UserIcon size={16} color={defaultTheme.colors.gray} />
    </IconWrapper>
  );
};

const renderTeamName = (name: string, usersLength: number) => {
  const teamMemberDescription = usersLength > 1 ? ` (${usersLength} members)` : ` (${usersLength} member)`;

  return (
    <>
      <ValueStrongPart>{name}</ValueStrongPart>&nbsp;{teamMemberDescription}
    </>
  );
};

const renderTokenName = (name: string) => {
  const { label, value } = getTokenDetails(name);

  return (
    <>
      {label}: <ValueStrongPart>{value}</ValueStrongPart>
    </>
  );
};

const OptionName: React.FC<{ name: string; isTeam: boolean; isToken: boolean; usersLength: number }> = ({
  name,
  isTeam,
  isToken,
  usersLength
}) => {
  if (isTeam) {
    return renderTeamName(name, usersLength);
  }
  if (isToken) {
    return renderTokenName(name);
  }

  return <ValueStrongPart>{name}</ValueStrongPart>;
};

export const OptionItem: React.FC<IProps> = (props) => {
  const { name, avatarUrl, onClick, hideAvatar, isTeam, usersLength } = props;
  const thumb = hideAvatar ? null : getThumbnail(name, avatarUrl, isTeam);
  const isToken = name.includes(': ');

  return (
    <OptionItemWrapper className="cq-user-select-dropdown-item" onClick={onClick}>
      {thumb}

      <OptionName name={name} isTeam={isTeam} isToken={isToken} usersLength={usersLength} />
    </OptionItemWrapper>
  );
};
