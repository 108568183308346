import { postGraphql } from '@services/api/base/graphql';
import { WidgetSettings } from '@features/Analytics/types';
import { ProjectReportsConnection, Team } from '@generated/types/graphql';
import { gql } from 'graphql-request';
import { Property } from '@types';
import { BarData } from '../../Widget/types';
import { buildAggregationResponseFields, buildRequestParams, extractGroupAggregationValue } from './helpers';
import { postProcessingData } from './postProcessingData';
import { RecordsWidgetDataParams } from '../types';

const requestGroupedData = (
  companyId: number,
  settings: WidgetSettings,
  propertiesMap: Record<number, Property>,
  teamsMap: Record<number, Team>
) => {
  const { filter, condition, groupBy } = buildRequestParams({ companyId, settings, propertiesMap, teamsMap });

  const aggregationResponseFields = buildAggregationResponseFields(settings, propertiesMap);

  return postGraphql<{ projectReportsConnection: ProjectReportsConnection }>(
    gql`
        query RECORDS_BAR_LIKE_DATA_QUERY($filter: ProjectReportFilter!, $condition: ProjectReportCondition, $groupBy: [ProjectReportGroupBy!]!) {
            projectReportsConnection(filter: $filter, condition: $condition) {
                groupedAggregates(groupBy: $groupBy) {
                    keys
                    ${aggregationResponseFields}
                }   
            }
        }
    `,
    {
      filter,
      condition,
      groupBy
    }
  );
};

export const fetchRecordsBarLikeData = async ({
  companyId,
  settings,
  stagesMap,
  usersMap,
  userToTeamsMap,
  propertiesMap,
  timezone,
  teamsMap
}: RecordsWidgetDataParams): Promise<BarData> => {
  const result = await requestGroupedData(companyId, settings, propertiesMap, teamsMap);

  return postProcessingData({
    data: {
      points: result.projectReportsConnection.groupedAggregates.map((group) => {
        const x = group.keys[0] as string;

        const isEmpty = x === 'None' || x === null;

        return {
          x: isEmpty ? 'Empty' : x,
          originalX: (isEmpty ? null : x) as string | number | number[] | null,
          isEmpty,
          y: Math.round(parseFloat(extractGroupAggregationValue(settings, group, propertiesMap)) * 100) / 100
        };
      })
    },
    settings,
    stagesMap,
    usersMap,
    userToTeamsMap,
    timezone
  });
};
