import { dateRangeConfig } from '@features/Analytics/dateRangeConfig';
import {
  AnalyticsWidgetAggregateFunction,
  AnalyticsWidgetDateRangeType,
  AnalyticsWidgetType,
  FilterGroupOperator,
  WidgetFilter,
  WidgetFilters,
  WidgetSettings
} from '@features/Analytics/types';
import { DeepPartial } from 'redux';
import { DateTime } from 'luxon';
import { Team } from '@generated/types/graphql';
import { InternalConfigutationError } from '../InternalConfigurationError';
import { Point } from '../Widget/types';

interface FilterWithCreatedAt {
  createdAt: {
    greaterThanOrEqualTo: string;
    lessThanOrEqualTo: string;
  };
}

export const calcDiffInDaysForDateRangeOption = (
  dateRangeType: AnalyticsWidgetDateRangeType,
  dateRangeStartDate?: string,
  dateRangeEndDate?: string
): number => {
  const dateRangeOption = dateRangeConfig[dateRangeType];

  if (!dateRangeOption) {
    throw new InternalConfigutationError(`Date range type ${dateRangeType} is not exist`);
  }

  if (dateRangeType === AnalyticsWidgetDateRangeType.ALL_TIME) {
    return 0;
  }

  const startDate = dateRangeOption.startDate ? dateRangeOption.startDate() : DateTime.fromISO(dateRangeStartDate);
  const endDate = dateRangeOption.endDate ? dateRangeOption.endDate() : DateTime.fromISO(dateRangeEndDate);

  if (!startDate || !endDate) {
    return 0;
  }

  return endDate.diff(startDate, 'days').days;
};

export const buildCreateAtDateRangeFilter = <TFilterType extends FilterWithCreatedAt>(
  settings: WidgetSettings,
  calculatePreviousPeriod = false
): DeepPartial<TFilterType> => {
  const dateRangeOption = dateRangeConfig[settings.dateRangeType];

  if (settings.widgetType === AnalyticsWidgetType.TIMELINE) {
    return {};
  }

  if (settings.dateRangeType === AnalyticsWidgetDateRangeType.ALL_TIME) {
    return {};
  }

  if (!dateRangeOption) {
    throw new InternalConfigutationError(`Date range type ${settings.dateRangeType} is not exist`);
  }

  const result = {} as DeepPartial<TFilterType>;

  let startDate = dateRangeOption.startDate ? dateRangeOption.startDate().toISO() : settings.dateRangeStart;
  let endDate = dateRangeOption.endDate ? dateRangeOption.endDate().toISO() : settings.dateRangeEnd;

  if (!startDate && !endDate) {
    return {};
  }

  if (calculatePreviousPeriod) {
    const startDateDateTime = startDate ? DateTime.fromISO(startDate) : undefined;
    const endDateDateTime = endDate ? DateTime.fromISO(endDate) : undefined;

    const diff = endDateDateTime.diff(startDateDateTime, 'days').days;

    startDate = DateTime.fromISO(startDate).minus({ days: diff }).toISO();
    endDate = DateTime.fromISO(endDate).minus({ days: diff }).toISO();
  }

  result.createdAt = {
    greaterThanOrEqualTo: startDate,
    lessThanOrEqualTo: endDate
  };

  return result;
};

interface IGraphqlFilter<TFilterType> {
  and?: IGraphqlFilter<TFilterType>[];
  or?: IGraphqlFilter<TFilterType>[];
}

export const applyFiltersGroup = <TOutput extends IGraphqlFilter<TOutput>>(
  filters: WidgetFilters,
  logicalOperator: FilterGroupOperator,
  applySingleFilterFn: (filter: WidgetFilter) => TOutput,
  teamsMap: Record<number, Team>
): IGraphqlFilter<TOutput> => {
  const group: IGraphqlFilter<TOutput>[] = [];

  filters.children.forEach((filter) => {
    if ('children' in filter) {
      group.push(applyFiltersGroup(filter, filter.operator, applySingleFilterFn, teamsMap));

      return;
    }

    group.push(applySingleFilterFn(filter));
  });

  return logicalOperator === FilterGroupOperator.AND ? { and: group } : { or: group };
};

const aggregationFunctions: Record<AnalyticsWidgetAggregateFunction, (values: number[]) => number> = {
  [AnalyticsWidgetAggregateFunction.AVG]: (values) => values.reduce((sum, v) => sum + v, 0) / values.length,
  [AnalyticsWidgetAggregateFunction.MIN]: (values) => Math.min(...values),
  [AnalyticsWidgetAggregateFunction.MAX]: (values) => Math.max(...values),
  [AnalyticsWidgetAggregateFunction.SUM]: (values) => values.reduce((sum, v) => sum + v, 0),
  [AnalyticsWidgetAggregateFunction.COUNT]: (values) => values.reduce((sum, v) => sum + v, 0)
};

const NO_TEAM_TEAM = 'No team';

export const remapAndAggregateByTeamPoints = ({
  points,
  userToTeamsMap,
  aggregationFunction
}: {
  points: Point[];
  userToTeamsMap: Record<number, string[]>;
  aggregationFunction: AnalyticsWidgetAggregateFunction;
}) => {
  // Prepare a map to hold points aggregated by team
  const teamPointsMap: Record<string, number[]> = {};

  const teamUsersMap: Record<string, number[]> = {};

  points.forEach(({ x, y, originalX }) => {
    const userId = originalX ? parseInt(x, 10) : null;
    const userTeams = userId && userToTeamsMap[userId] ? userToTeamsMap[userId] : [NO_TEAM_TEAM];

    userTeams.forEach((team) => {
      if (!teamPointsMap[team]) {
        teamPointsMap[team] = [];
      }
      teamPointsMap[team].push(y);

      if (!teamUsersMap[team]) {
        teamUsersMap[team] = [];
      }
      teamUsersMap[team].push(userId);
    });
  });

  // Aggregate points by team
  const aggregatedPoints = Object.keys(teamPointsMap).map((team) => {
    const values = teamPointsMap[team];

    return {
      x: team,
      isEmpty: false,
      originalX: teamUsersMap[team],
      y: aggregationFunctions[aggregationFunction](values)
    };
  });

  return aggregatedPoints;
};
