import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Form, useForm, FormValidationRules, SelectField, RichTextField } from '@kit/components/Form';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUserId } from '@state/selectors';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { getFullName } from '@utils/utils';
import { useTokensWithValuesWithRecordId } from '@hooks/useTokens';
import { useEmails } from '@hooks/useEmails';
import { clearQuillValue, convertQuillFormatToText, isQuilValueEmpty } from '@utils/quill';
import { splitByComma } from '@utils/strings';
import { RecordType } from '@types';
import { Maximize2 } from 'react-feather';
import { useModal } from '@common/PromiseModal';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { useEmailAccountList } from '@hooks/workspace/emails/useEmailAccountList';
import { QueryParamsEnum, useQueryParamMutation } from '@hooks/useQueryParam';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { ActionButtons, BodyWithActionButtons, CcAndBccButtons, ModalBody } from './styled';

interface FormValues {
  account: { id: number; title: string };
  to: { id: number; title: string; name: string; email: string }[];
  subject: string;
  body: string;
  cc: string;
  bcc: string;
}
interface Props {
  recordId: number;
  recordType: RecordType;
  onClose: () => void;
  reply?: {
    toMessageId: number;
    to: { email: string }[];
    subject: string;
    from?: string;
  };

  isExpandable?: boolean;
  initialValues?: FormValues;
}

export const EmailForm = ({ recordId, recordType, onClose, reply, isExpandable = true, initialValues }: Props) => {
  const [isCcFieldShown, setIsCcFieldShown] = useState(false);
  const [isBccFieldShown, setIsBccFieldShown] = useState(false);
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { data: companyEmails = [] } = useEmailAccountList();
  const { data: record } = useRecordDetail(recordId, { refetchOnMount: false });

  const { sendMutation } = useEmails();

  const isReply = Boolean(reply);

  const { data: companyUsers = [] } = useAllCompaniesUsers();

  const tokens = useTokensWithValuesWithRecordId(recordId, recordType);

  const companyMembersOptions = companyUsers
    .filter(({ email }) => email)
    .map(({ id, email, firstName, lastName }) => ({
      id: `isCompanyUser-${id}`,
      email,
      title: `${getFullName({ firstName, lastName })} (${email})`,
      name: getFullName({ firstName, lastName }),
      isCompanyUser: true
    }));

  const contactsOptions = (record?.projectContacts ?? [])
    .filter(({ emails }) => emails?.length > 0)
    .flatMap(({ id, name, emails }) =>
      emails!.map((email: string) => ({
        title: `${name} (${email})`,
        id: `isContact-${id}-${email}`,
        email,
        name,
        isContact: true
      }))
    );

  const fromFieldOptions = useMemo(
    () =>
      companyEmails
        .filter((email) => email.emailAccountUsers?.some((user) => user.user?.id === currentUserId && user.canSend))
        .map(({ id, emailAddress }) => ({
          id,
          title: emailAddress
        })) || [],
    [companyEmails, currentUserId]
  );

  const { setParams } = useQueryParamMutation();

  const toFieldOptions = [...contactsOptions, ...companyMembersOptions];

  const postForm = async (values: FormValues) => {
    const dto = isReply
      ? {
          body: convertQuillFormatToText(values.body, true),
          sendAt: 'now',
          replyToMessageId: reply.toMessageId,
          to: reply.to,
          subject: reply.subject
        }
      : {
          to: values.to.map(({ name, email }) => ({ name, email })),
          cc: splitByComma(clearQuillValue(convertQuillFormatToText(values.cc))),
          bcc: splitByComma(clearQuillValue(convertQuillFormatToText(values.bcc))),
          subject: clearQuillValue(convertQuillFormatToText(values.subject, true)),
          body: convertQuillFormatToText(values.body, true),
          sendAt: 'now'
        };

    await sendMutation.mutateAsync({
      dto,
      recordId,
      accountId: values.account.id
    });

    setParams(
      {
        [QueryParamsEnum.FeedCursor]: undefined,
        [QueryParamsEnum.FeedId]: undefined
      },
      true
    );

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: initialValues ?? {
      account: null
    }
  });

  const {
    formState: { isSubmitting, errors },
    control,
    setValue,
    watch
  } = form;

  const account = watch('account');

  useEffect(() => {
    if (fromFieldOptions?.length && !account) {
      if (reply?.from) {
        setValue('account', fromFieldOptions.find(({ title }) => title === reply.from) ?? fromFieldOptions[0]);
      } else {
        setValue('account', fromFieldOptions[0]);
      }
    }
  }, [fromFieldOptions, account, setValue, reply?.from]);

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      account: { isRequired: true },
      to: { isRequired: !isReply },
      subject: {
        isRequired: !isReply,
        validate: isReply
          ? undefined
          : (value) => {
              if (value && isQuilValueEmpty(value as string)) {
                return 'This is required';
              }

              return undefined;
            }
      },
      body: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      }
    }),
    [isReply]
  );

  const toFieldOptionsGrouping = (option: ToFieldOption) => {
    if (option.isContact) {
      return 'Contacts';
    }

    if (option.isCompanyUser) {
      return 'Workspace members';
    }

    return '';
  };

  const { openModal } = useModal();

  const handleExpandClick = useCallback(() => {
    const values = form.getValues();
    form.reset({ account: null, to: [], subject: '', body: '', cc: '', bcc: '' });
    onClose?.();

    openModal<void>(
      ({ onClose: onModalClose }) => (
        <ModalBody>
          <EmailForm
            recordId={recordId}
            onClose={() => {
              onModalClose();
            }}
            initialValues={values}
            isExpandable={false}
          />
        </ModalBody>
      ),
      {
        title: 'New email'
      }
    );
  }, [form, onClose, recordId, openModal]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <SelectField
        name="account"
        label="From"
        options={fromFieldOptions}
        control={control}
        getOptionLabel={(option) => option.title}
      />

      {!isReply && (
        <SelectField
          name="to"
          label="To"
          getOptionLabel={(option) => option.title}
          getOptionSelected={(op, val) => op.id === val.id}
          options={toFieldOptions}
          control={control}
          isMulti
          groupBy={toFieldOptionsGrouping}
        />
      )}

      {(!isCcFieldShown || !isBccFieldShown) && (
        <CcAndBccButtons>
          {!isCcFieldShown && (
            <Button
              isUpperCase={false}
              variant={ButtonVariant.Flat}
              size={ButtonSize.Small}
              onClick={() => setIsCcFieldShown(true)}
            >
              Add CC
            </Button>
          )}
          {!isBccFieldShown && (
            <Button
              isUpperCase={false}
              variant={ButtonVariant.Flat}
              size={ButtonSize.Small}
              onClick={() => setIsBccFieldShown(true)}
            >
              Add BCC
            </Button>
          )}
        </CcAndBccButtons>
      )}

      {isCcFieldShown && (
        <RichTextField
          name="cc"
          getTokens={tokens.getTokensFieldTo}
          isToolbarShown={false}
          isToolbarHiddenCompletely
          isOneLine
          control={control}
          label="CC"
          placeholder=""
        />
      )}

      {isBccFieldShown && (
        <RichTextField
          name="bcc"
          getTokens={tokens.getTokensFieldTo}
          isToolbarShown={false}
          isToolbarHiddenCompletely
          isOneLine
          control={control}
          label="BCC"
          placeholder=""
        />
      )}

      {!isReply && (
        <RichTextField
          name="subject"
          getTokens={tokens?.getAllTokens}
          isToolbarShown={false}
          isToolbarHiddenCompletely
          isOneLine
          control={control}
          label="Subject"
          placeholder=""
        />
      )}

      <BodyWithActionButtons>
        <RichTextField
          label={isReply ? 'Message' : undefined}
          name="body"
          getTokens={tokens?.getAllTokens}
          control={control}
          placeholder="Type here..."
        />
        <ActionButtons isBodyWithError={Boolean(errors.body)}>
          {isExpandable && (
            <Button
              size={ButtonSize.Small}
              isUpperCase={false}
              variant={ButtonVariant.Flat}
              onClick={handleExpandClick}
            >
              <Maximize2 size="16px" />
              Expand
            </Button>
          )}

          <Button variant={ButtonVariant.Flat} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
            Send
          </Button>
        </ActionButtons>
      </BodyWithActionButtons>
    </Form>
  );
};
