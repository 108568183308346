import React, { useCallback } from 'react';
import { XIcon } from '@kit/ui/icons/X';
import { Archive, Edit, Trash2 } from 'react-feather';
import { colors } from '@styles';
import { useConfirmModal, useModal } from '@common/PromiseModal';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { useProjectBulk } from '@hooks/useProjectBulk';
import { useRoutes } from '@hooks/useRoutes';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUserId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { RecordType } from '@types';
import { UnarchiveIcon } from '@kit/ui/icons/Unarchive';
import { Tooltip } from '@material-ui/core';
import { BulkEditRecordForm } from '@features/ProjectPortfolio/List/BulkActions/Edit';
import { SelectedLabel, SelectionControlPanel } from './styled';

interface Props {
  selectedIds: number[];
  clearSelection: () => void;
  isAllSelectedArchived: boolean;
}

export const SelectionControl = ({ selectedIds, clearSelection, isAllSelectedArchived }: Props) => {
  const { companyId } = useRoutes();
  const userId = useAppSelector(selectCurrentUserId);
  const { data: access } = useUserRoleSettings(companyId, userId);

  const queryClient = useQueryClient();

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([ReactQueryKey.WorkspaceAccounts]);
    queryClient.invalidateQueries([ReactQueryKey.ClientsListInitialGroupData]);
    queryClient.invalidateQueries([ReactQueryKey.ClientsByIds]);
    queryClient.invalidateQueries([ReactQueryKey.RecordDetail]);
  }, [queryClient]);

  const {
    archive: { mutateAsync: bulkArchiveProjects },
    remove: { mutateAsync: bulkRemoveProjects }
  } = useProjectBulk(RecordType.ACCOUNT);

  const confirm = useConfirmModal();
  const { openModal } = useModal();

  const handleEdit = useCallback(() => {
    openModal<void>(
      ({ onClose }) => (
        <BulkEditRecordForm
          recordIds={selectedIds}
          recordType={RecordType.ACCOUNT}
          onClose={() => {
            invalidateCache();
            clearSelection();
            onClose();
          }}
        />
      ),
      {
        title: 'Bulk edit'
      }
    );
  }, [openModal, selectedIds, invalidateCache, clearSelection]);

  const handleUnarchive = useCallback(async () => {
    if (await confirm({ confirmText: 'Are you sure you want to perform this action?' })) {
      await bulkArchiveProjects({
        projects: selectedIds,
        isArchive: false
      });

      invalidateCache();
      clearSelection();
    }
  }, [confirm, bulkArchiveProjects, invalidateCache, clearSelection, selectedIds]);

  const handleArchive = useCallback(async () => {
    if (await confirm({ confirmText: 'Are you sure you want to perform this action?' })) {
      await bulkArchiveProjects({
        projects: selectedIds,
        isArchive: true
      });

      invalidateCache();
      clearSelection();
    }
  }, [confirm, bulkArchiveProjects, invalidateCache, clearSelection, selectedIds]);

  const handleDelete = useCallback(async () => {
    if (await confirm({ confirmText: 'Are you sure you want to perform this action?' })) {
      await bulkRemoveProjects({
        projects: selectedIds
      });

      invalidateCache();
      clearSelection();
    }
  }, [confirm, bulkRemoveProjects, invalidateCache, clearSelection, selectedIds]);

  return (
    <SelectionControlPanel>
      <XIcon onClick={clearSelection} size="24px" color="#9C9CAA" />
      <SelectedLabel>{selectedIds.length} selected:</SelectedLabel>
      {hasAccess(access!, 'project', 'edit') && (
        <Tooltip title="Edit">
          <Edit onClick={handleEdit} size="16px" color={colors.green} />
        </Tooltip>
      )}
      {hasAccess(access!, 'project', 'edit') && !isAllSelectedArchived && (
        <Tooltip title="Archive">
          <Archive onClick={handleArchive} size="16px" color={colors.green} />
        </Tooltip>
      )}
      {isAllSelectedArchived && (
        <>
          {hasAccess(access!, 'project', 'edit') && (
            <Tooltip title="Unarchive">
              <UnarchiveIcon onClick={handleUnarchive} size="16px" color={colors.green} />
            </Tooltip>
          )}
          {hasAccess(access!, 'project', 'delete') && (
            <Tooltip title="Delete">
              <Trash2 onClick={handleDelete} size="16px" color={colors.green} />
            </Tooltip>
          )}
        </>
      )}
    </SelectionControlPanel>
  );
};
