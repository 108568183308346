import { StageStatus } from './types';

export const STAGE_STATUS_CONFIG: { [key in StageStatus]: { color: string; bgColor: string; text: string } } = {
  [StageStatus.NOT_STARTED]: {
    color: '#778CA2',
    bgColor: '#FFFFFF',
    text: 'Not started'
  },
  [StageStatus.IN_PROGRESS]: {
    color: '#4D7CFE',
    bgColor: '#DBE5FF',
    text: 'In progress'
  },
  [StageStatus.COMPLETED]: {
    color: '#009A47',
    bgColor: '#CDF3DF',
    text: 'Completed'
  }
};
