export const extractEntityIdFromUrl = (url: string, entityType: DrawerEntity) => {
  const parts = url.split('/');
  const index = parts.indexOf(`${entityType}s`);

  if (index === -1) {
    return null;
  }

  return parseInt(parts[index + 1], 10);
};
